export const cookie = [
  {
    Head: "COOKIE POLICY",
    desc1:
      "Our company “Trustline” uses cookies on website to improve your browsing experience and to understand how you use our website. This Cookie Policy explains what cookies are, how we use them, and how you can control them.",
    sub1: " What are Cookies?",
    sub1_desc:
      "Cookies are small text files that are stored on your device when you visit a website. They are used to remember your preferences and to understand how you use the website. Cookies can be either session cookies, which are deleted when you close your browser, or persistent cookies, which remain on your device until they expire or you delete them.",
    sub2: " What types of cookies do we use? ",
    sub_semi: " We use the following categories of cookies on our website:",
    sub2_desc1:
      " Essential Cookies: These cookies are essential for the proper functioning of our website. They allow you to navigate our website and use its features. Without these cookies, certain functions of our website would not be available.",
    sub2_desc2:
      " Analytics Cookies: We use analytics cookies to gather information about how visitors use our website. This information enables us to improve the functionality and user-friendliness of our website. We use tools like Google Analytics to track your activity on our website.",
    sub2_desc3:
      " Marketing Cookies: We use marketing cookies to track your activity on our website and display targeted advertisements. These cookies enable us to present you with ads that are relevant to your browsing history and interests. We use tools like Facebook Pixel to track your activity on our website.",
    sub2_desc4:
      " Third-Party Cookies: We may also use cookies from third-party websites or services, such as social media platforms. These cookies are subject to the policies of their respective providers.",
    sub3: " How can you control cookies? ",
    sub3_desc1:
      "You can regulate your use of cookies through your browser settings. Most browsers enable you to block or delete cookies. However, please note that blocking or deleting cookies may impair your ability to use our website and its features. ",
    sub3_desc2:
      "In addition to browser settings, you can also manage your cookie preferences through our cookie banner. The cookie banner will give you the option to enable or disable certain types of cookies.",
    sub4: " Changes to this Cookie Policy:",
    sub4_desc:
      " We reserve the right to update this Cookie Policy from time to time. Any amendments will be posted to this page, and as such, we recommend that you review this policy periodically.",

    sub6: "Contact Us",
    sub6_desc1:
      "By using our website, you acknowledge and consent to our use of cookies as outlined in this Cookie Policy.  ",
    sub6_desc2:
      "If you have any questions about our Cookie Policy, please contact us.  ",
    li1: "Trustline",
    li2: "Unit 404, Nastaran Tower, Jadaf Waterfront, Al Jadaf, Dubai, UAE",
    li3_2: "+971 58 192 0960",
    li3: "+9714 397 8705",
    li4: "hello@trustlinedigital.com",
  },
];
export const cookie_consent = [
  {
    h3: "Hi there,",
    p1: "We utilize cookies to enhance your browsing experience and analyze site traffic. You can opt to close this and proceed with essential cookies only. Further information can be found in our",
    link: " Cookie Policy. ",

    img: "./Images/cookie/Trustline-Digital-Cookie-Policy.svg",
    alt: "Our cookie policy is simple to comprehend and complies with privacy laws. See how our website uses cookies.",
  },
];
