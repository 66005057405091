import React, { useState, useEffect } from "react";
import axios from "axios";

const SitemapXML = () => {
  const [staticSitemap, setStaticSitemap] = useState("");
  const [dynamicSitemap, setDynamicSitemap] = useState("");

  useEffect(() => {
    axios
      .get("https://trustlinedigital.com/sitemap.xml")
      .then((response) => {
        setStaticSitemap(response.data);
      })
      .catch((error) => {
        console.error("Error fetching static sitemap:", error);
      });

 
    axios
      .get(`${process.env.REACT_APP_API_URL}/sitemap.xml`)  
      .then((response) => {
        setDynamicSitemap(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dynamic sitemap:", error);
      });
  }, []);

  // Combine both static and dynamic sitemaps
  const combinedSitemap = `${staticSitemap}\n${dynamicSitemap}`;

  return <div>{combinedSitemap && <pre>{new XMLSerializer().serializeToString(new DOMParser().parseFromString(combinedSitemap, "text/xml"))}</pre>}</div>;
};

export default SitemapXML;
