export const display = [
  {
    main_heading: "Display & Video Advertising  ",

    sub_heading_grad: "Maximize  ",

    sub_heading1_2: "your advertising channels and turn heads  ",
    sub_heading2: "How Display & Video Advertising can boost your business?  ",
    sub_heading3:
      "Display & Video Advertising Services We Offer to Businesses in the UAE   ",
    sub_heading4: "Our Process ",
    text1:
      "In today's digital world, display and video advertising have become essential components of a successful marketing strategy. These mediums allow you to communicate your brand message through visually appealing and emotionally engaging content, which is more likely to grab people's attention and drive engagement. Whether you want to showcase your products, promote your services, or raise brand awareness, display and video advertising can help you achieve your goals. By using advanced targeting options and ad formats, you can reach the right audience at the right time and maximize your advertising channels' potential. With our display and video advertising services based in Dubai, UAE, we'll help you create compelling ad content and leverage the latest advertising technologies to achieve your business objectives.  ",
    bullets: [
      {
        list_item: "Increases brand recognition within your intended audience.",
      },
      { list_item: "Customers can be engaged and re-engaged." },
      { list_item: "Drive frequency of exposure." },
      {
        list_item:
          "Choose from a variety of ad formats to meet your advertising objectives.",
      },
      { list_item: "Drive consideration towards your product or service." },
      { list_item: "You have the option to expand your reach." },
      {
        list_item:
          "Target individuals according to the content they are viewing.",
      },
    ],
    cover:
      "./Images/services/display-video/Trustline-Display-Video-Advertsing.svg",
    alt: "Drive brand awareness and conversions with our display and video advertising services.",
  },
];

export const display_services = [
  {
    title: "Google Display Network (GDN) ",
    desc: "We will use this system to deliver google display ads to users based on the content they are browsing. This would let your company advertise its goods and services on more than 2 million websites, reaching 90% of the world's internet users.",
  },
  {
    title: "Facebook, Twitter & Instagram",
    desc: "Putting together a successful Display Ad Campaign is something we are well-versed in doing. We will assist you in reaching out to possible customers and growing your audience.",
  },
  {
    title: " YouTube",
    desc: "YouTube is a fantastic social media network for video sharing and gaining exposure to new clients, alongside Facebook, Twitter, and Instagram. We help you maximize your reach by utilizing both non-skippable and skippable in-stream YouTube ads.",
  },
  {
    title: " Remarketing Ads",
    desc: "Retargeting ads enable you to connect with users who have recently visited your website by displaying your Google Banner advertisements as they continue to use Google. It will help you connect with your target audience and ensure that when a customer is ready to buy, your brand comes to mind.",
  },
];
export const display_process = [
  {
    desc: "Looking for Professional Display & Video Advertising  Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your Display & Video Advertising needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const display_process_cards = [
  {
    icon: "./Images/services/display-video/Trustline-Display-Advertising-Objectives.svg",
    alt: "Optimize Display & Video ad campaigns with research, media selection, objectives, and KPIs.",
    title: "Objectives",
    bullets: [
      { list_item: "Campaign Objectives  " },
      { list_item: "Competitor Research  " },
      { list_item: "Select Media Channels  " },
      { list_item: "Establish KPI’s  " },
    ],
  },
  {
    icon: "./Images/services/display-video/Trustline-Display-Advertising-Development.svg",
    alt: "Display & Video Advertising strategy, budgeting, creative/design production, content creation.",
    title: "Development",
    bullets: [
      { list_item: "Developing Strategy  " },
      { list_item: "Campaign budgeting  " },
      { list_item: "Creative/Design Production  " },
      { list_item: " Content Production " },
    ],
  },
  {
    icon: "./Images/services/display-video/Trustline-Display-Advertising-Launch.svg",
    alt: "We create display and video ad campaigns by optimizing content and conducting experiments and then proceed to launch. ",
    title: "Launch",
    bullets: [
      { list_item: "Implement A/B Testing  " },
      { list_item: "Experiment with Ads & Content  " },
      { list_item: "Set Up Campaign  " },
      { list_item: "Campaign Launch  " },
    ],
  },
  {
    icon: "./Images/services/display-video/Trustline-Display-Advertising-Evaluate.svg",
    alt: "We evaluate Display & Video Advertising campaigns with analytics review and reporting.",
    title: "Evaluate",
    bullets: [
      { list_item: "Review Analytics  " },
      { list_item: "Optimize Campaign  " },
      { list_item: "Generate Reports  " },
    ],
  },
];
