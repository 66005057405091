export const empty = [
  {
    head: "Sorry, we can't find that page!",
    desc: " Don't worry though, let’s get you back on track",
    image: "./Images/not-found/Error_Desktop.svg",
    image2:"./Images/home/ How-we-do-it/Company Green Icons/Trustline-Review-Process.svg",
    alt: "Sorry, the page you are looking for cannot be found.",
    buttontext1: " Go Back Home",
    buttontext2: "Contact Us",
  },
];
