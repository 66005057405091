import { HiDevicePhoneMobile } from "react-icons/hi2";

import { RiPhoneLine } from "react-icons/ri";

export const nav = [
  {
    logogreen: "./Images/logo/green/Navbar_Green_Logo_Default.svg",
    alt1: "Trustline's digital marketing company website displays a green logo.",
    logowhite: "./Images/logo/white/Navbar_White_Logo.svg",
    alt2: "The white Trustline logo embodies our corporate identity.",
  },
];
export const menupage = [
  {
    text: "Let’s Get Started Today!",
    span1: "Call Us ",
    span2: "Reach Out",
    contact1: "+971 58 192 0960",
    contact2: "+9714 397 8705",
    email: "hello@trustlinedigital.com",
    connect: "Connect With Us",
    phone_icon: <RiPhoneLine />,
    phone_icon2: <HiDevicePhoneMobile />,
    phone_number1: "+9714 397 8705",
    phone_number2: "+971 58 192 0960",
  },
];
export const menupage_links = [
  { l1: "Home", linkto: "/" },
  { l1: "About", linkto: "/about" },
  { l1: "Services", linkto: "/services" },
  { l1: "Blogs", linkto: "/blogs" },
  { l1: "Contact Us", linkto: "/contact-us" },
];
