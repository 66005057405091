import React from "react";
import { useState } from "react";
import image1 from '../../../Images/home/ Why-Choose-Us/Default/Trustline-Enhanced-Expertise.svg';
import image2 from '../../../Images/home/ Why-Choose-Us/Hover/Trustline-Enhanced-Expertise-Tools.svg';
import image3 from '../../../Images/home/ Why-Choose-Us/Default/Trustline-Creative-Marketing.svg';
import image4 from '../../../Images/home/ Why-Choose-Us/Hover/Trustline-Creative-Marketing-Activity.svg';
import image5 from '../../../Images/home/ Why-Choose-Us/Default/Trustline-New-Perspective.svg';
import image6 from '../../../Images/home/ Why-Choose-Us/Hover/Trustline-Fresh-Perspective.svg';
import image7 from '../../../Images/home/ Why-Choose-Us/Default/Trustline-Lower-Costs.svg';
import image8 from '../../../Images/home/ Why-Choose-Us/Hover/Trustline-Reduced-Costs.svg';
import { whychooseus, home_title } from "../../../data/Homedata";
const images = [image1, image2, image3, image4, image5, image6, image7, image8];

const HomeWhyChooseUs = () => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };
  return (
    <>
      <section className="Why-choose-us section-padding background-baby">
        <div className="container  d-flex flex-column gap-2 ">
          <div className="row p-4">
            {home_title.map((val, index) => (
              <div className="col-sm-12 bold text-center" key={index}>
                <h2 className="bold py-lg-3 py-2"> {val.why_choose_us}</h2>
              </div>
            ))}
          </div>
          {whychooseus.map((val, index) => (
            <div className="row d-flex pb-2 flex-column" key={index}>
              <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xl-6    mx-auto ">
                <div
                  key={index}
                  className={`card h-25  w-80  ${
                    hoveredIndex === index ? "card-hover2" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="row d-flex  py-2   flex-row px-2 px-md-0  ">
                    <div className="col-4  col-sm-5 col-md-3 col-lg-3  col-xl-3 col-xxl-3 ps-4 m-auto gap-0  position-relative ">
                      <div className="    float-start ">
                      <img
              src={hoveredIndex === index ? images[index*2+1] : images[index*2]}
                          alt={val.alt1}
                        />
                      </div>
                    </div>

                    <div className="col-8 col-sm-7  col-md-9 col-lg-9 col-xl-9 col-xxl-9 justify-content-start ms-0 float-start my-auto d-flex flex-column">
                      <h4 className="semibold">{val.head} </h4>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default HomeWhyChooseUs;
