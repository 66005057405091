import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { HiPlus } from "react-icons/hi";
import { primary } from "../../../data/buttons";

import {
  graphic_design,
  graphic_design_services,
  graphic_design_process,
  graphic_design_process_cards,
} from "../../../data/services/graphic-design";
import "./servicespages.css";
import { AiOutlineCheckCircle } from "react-icons/ai";
import ReadyToGrow from "../../../components/banner/ReadyToGrow";
import { PrimaryButton } from "../../../components/buttons/primary-button/PrimaryButton";
import Swiperslider from "../services-slider/ServicesSlider";
import DefaultNav from "../../../components/navbar/defaultnav/DefaultNav";
import GTM from "../../../googletagmanager/GTM";
import { GraphicDesignHelmet } from "../../../preclicktags/PreClick";

const GraphicDesign = () => {
  const [numCards, setNumCards] = useState(2);
  const [numCards2, setNumCards2] = useState(2);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (!isMobile) {
      setNumCards2(graphic_design_process_cards.length);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setNumCards(graphic_design_services.length);
    }
  }, [isMobile]);
  const handleShowMore = () => {
    setNumCards(numCards + 1);
  };
  const handleShowMoreProcess = () => {
    setNumCards2(numCards2 + 2);
  };
  return (
    <>
      <GTM />
      <GraphicDesignHelmet />
      <DefaultNav />
      <section className="mt-5  graphic_design services background-baby">
        {graphic_design.map((val) => (
          <>
            {/*Services  section begins */}
            <div className="container section-padding">
              {/**breadcrumb */}
              <div className="row d-flex justify-content-between">
                <div className="col-lg-6 col-12">
                  <h1 className="bold py-lg-3 py-2">{val.main_heading}</h1>
                  <h3 className="semibold py-lg-3 py-3">
                    {val.sub_heading1}
                    <text className="gradient-text pe-1">
                      {val.sub_heading_grad}
                    </text>
                    {val.sub_heading1_2}
                    <text className="gradient-text ps-sm-1 pe-1 pe-sm-1">
                      {val.sub_heading_grad2}
                    </text>
                    {val.sub_heading1_3}
                  </h3>
                  <p>{val.text1}</p>

                  <h5 className="semibold py-2 py-lg-3">{val.sub_heading2}</h5>
                  <p className="bold pt-1">{val.text2_bold}</p>
                  <p>{val.text3}</p>
                  <PrimaryButton
                    buttonText={primary[1].buttonText1}
                    icon={primary[1].icon}
                  />
                </div>
                <div className="col-lg-6  ">
                  <div className="cover-image pt-lg-4 pt-3">
                    <img src={val.cover} alt={val.alt} />
                  </div>
                </div>
              </div>
            </div>
            {/*Services section ends */}

            {/*Services cards section begins */}
            <div className="container our-process-section section-padding">
              <div className=" text-center">
                <h3 className="semibold   py-lg-3 py-2">{val.sub_heading3}</h3>
              </div>

              <div className="row py-lg-4 py-sm-3 py-2 d-flex justify-content-center ">
                {graphic_design_services.slice(0, numCards).map((val) => (
                  <>
                    <div className="col-lg-4 col-sm-6 col-12 py-sm-3 py-2 ">
                      <div className="box-graphic p-lg-4  pt-4   p-sm-4 p-3  ">
                        <div className="card-header pb-lg-3 pb-1"> </div>
                        <h4 className="semibold  ">{val.title}</h4>

                        <ul className="bullets d-flex  pt-lg-2  list-unstyled flex-column">
                          {val.descs.map((desc) => (
                            <>
                              <li className="py-1 py-md-2 d-flex flex-row  ">
                                <div className="me-2 tick">
                                  <AiOutlineCheckCircle />
                                </div>
                                {desc.list_item}
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              {isMobile && numCards < graphic_design_services.length && (
                <div className="d-flex justify-content-center">
                  <button className="show-more-btn  " onClick={handleShowMore}>
                    <HiPlus /> Show More
                  </button>
                </div>
              )}
            </div>
            {/*Services cards section ends */}
            <ReadyToGrow />
            {/*Services process section begins */}
            <div className="container our-process-section section-padding">
              <div className=" text-center">
                <h3 className="semibold   py-lg-3 py-2">{val.sub_heading4}</h3>
                {graphic_design_process.map((val) => (
                  <p>{val.desc}</p>
                ))}
              </div>

              <div className="row py-lg-4 py-sm-3 py-2 process-cards-section">
                {graphic_design_process_cards.slice(0, numCards2).map((val) => (
                  <>
                    <div className="col-lg-3 col-sm-6 col-12 py-sm-3 py-2">
                      <div className="d-flex justify-content-end pe-3">
                        <div className="process-icon   ">
                          <img src={val.icon} alt={val.alt} />
                        </div>
                      </div>
                      <div className="box-process p-lg-4 p-sm-3 p-3 ">
                        <h4 className="semibold pt-lg-5 pt-sm-5 pt-3">
                          {val.title}
                        </h4>

                        <ul className="bullets d-flex  pt-lg-2  list-unstyled flex-column">
                          {val.bullets.map((bullet) => (
                            <>
                              <li className="py-1 py-md-2 d-flex flex-row  ">
                                <div className="me-2 tick">
                                  <AiOutlineCheckCircle />
                                </div>
                                {bullet.list_item}
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              {isMobile && numCards2 < graphic_design_process_cards.length && (
                <div className="d-flex justify-content-center">
                  <button
                    className="show-more-btn  "
                    onClick={handleShowMoreProcess}
                  >
                    <HiPlus /> Show More
                  </button>
                </div>
              )}
            </div>
            {/*Services process section ends */}
            <Swiperslider />
          </>
        ))}
      </section>
    </>
  );
};

export default GraphicDesign;
