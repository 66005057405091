import React, { useLayoutEffect, useRef, useState } from "react";
import lottie from "lottie-web";

const Loading = () => {
  const container = useRef(null);
  const [animationLoaded, setAnimationLoaded] = useState(false);
  useLayoutEffect(() => {
    if (!animationLoaded) {
      lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("./LoaderGif.json"),
        events: {
          complete: () => setAnimationLoaded(true),
        },
      });
    }
  }, [animationLoaded]);

  return (
    <>
      <div className="container" ref={container}></div>
    </>
  );
};

export default Loading;
