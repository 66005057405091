import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { BsWhatsapp, BsTelephone } from "react-icons/bs";
import { BiCalendarEvent } from "react-icons/bi";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { RiPhoneLine } from "react-icons/ri";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";
export const footer = [
  {
    logo: "Images/logo/white/Trustline_Logo_White.svg",
    alt: "The white Trustline logo embodies our corporate identity.",
    copyright:
      "Copyright © 2021 Trustline Digital Ventures Marketing Management CO. LLC. All Rights Reserved",
    background: "./Images/backgrounds/Trustline-Digital-Marketing-Dubai.svg",
    alt_footer:
      "Leading digital marketing company in Dubai offering innovative solutions to boost your online presence. Contact us today.",
    title1: "Your Gateway to Quantitative Marketing",
    desc: " We're committed to delivering custom data-driven solutions that drive growth, security, and success for your UAE-based business. With a focus on transparency, our in-house team of experts work tirelessly to ensure quality and deliver measurable results.",
  },
];
export const Getintouch = [
  {
    title2: "Get in touch",
    phone_icon: <RiPhoneLine />,
    phone_icon2: <HiDevicePhoneMobile />,
    phone_number1: "+9714 397 8705",
    phone_number2: "+971 58 192 0960",
    mail_icon: <HiOutlineMail />,
    mail_id: "hello@trustlinedigital.com",
    location_icon: <HiOutlineLocationMarker />,
    location_address:
      "Unit 404, Nastaran Tower, Jaddaf Waterfront, Al Jaddaf, Dubai, UAE",
  },
];
export const Overview = [
  {
    id: 1,
    title: "Overview",
    links: [
      { text: "About", linkto: "/about" },
      { text: "Services", linkto: "/services" },

      { text: " Contact Us", linkto: "/contact-us" },
      { text: "Sitemap ", linkto: "/sitemap" },
      { text: "Privacy Policy", linkto: "/privacy-policy" },
      { text: "Terms & Conditions", linkto: "/terms-and-conditions" },
    ],
  },
];
export const Services = [
  {
    id: 2,
    title: "Services",
    links: [
      {
        text: "Search Engine Optimization",
        linkto: "/search-engine-optimization",
      },
      {
        text: "Pay-per-click Advertising",
        linkto: "/pay-per-click-advertising",
      },
      { text: " Email Marketing", linkto: "/email-marketing" },
      { text: " Digital Strategy", linkto: "/digital-strategy" },
      { text: "Graphic Design", linkto: "/graphic-designing" },
      { text: "Web Design & Development", linkto: "/web-design-development" },
      { text: "Content Marketing ", linkto: "/content-marketing" },
      {
        text: "Display & Video Advertising",
        linkto: "/display-and-video-advertising",
      },
      { text: "Social Media Marketing", linkto: "/social-media-marketing" },
    ],
  },
];
export const Social_icons = [
  { id: 1, i: <AiOutlineFacebook /> },
  { id: 2, i: <AiOutlineInstagram /> },
  { id: 3, i: <AiOutlineLinkedin /> },
];
export const float_button = [
  {
    text1: "Call",
    text2: "WhatsApp",
    text3: "Book",
    icon: <BsTelephone />,
    icon2: <BsWhatsapp />,
    icon3: <BiCalendarEvent />,
  },
];
