import React from "react";
import Loading from "./Loading";
import "./preloader.css";
const Preloader = () => {
  return (
    <>
      <div className="loader-page">
        <div className="loader-component  d-flex align-self-baseline     mx-auto">
          <Loading />
        </div>
      </div>
    </>
  );
};

export default Preloader;
