export const privacy = [
  {
    Head: "PRIVACY POLICY",
    desc1:
      "At Trustline, we understand the importance of protecting the privacy of our customers. We are committed to ensuring that your personal information is protected in accordance with the laws of the United Arab Emirates. ",

    desc2:
      "This Privacy Policy explains how we collect, use, and protect your personal information. ",
    sub1: "  1.	Information we collect:  ",
    sub1_desc:
      "We collect personal information from clients in order to provide our services, including but not limited to, name, contact information, and billing information. We may also collect personal information from website visitors through the use of cookies and other technologies. This information may include an IP address, browsing activity, and device information.",
    sub2: " 2.	Use of Personal Information:",

    sub2_desc1:
      "We use personal information to provide our services, communicate with clients, and improve our website and services. We may also use personal information for marketing and advertising, but only if the client gives us permission to do so.",

    sub3: "3.	Sharing Information:   ",
    sub3_desc1:
      "We won't give personal information to third parties without the person's permission, unless it's required by law or to protect our company's rights and property.  ",

    sub4: "4.	Data Retention: ",
    sub4_desc:
      " We will retain personal information for as long as necessary to provide our services and as required by law. ",
    sub5: " 5.	Protection of Personal Information: ",
    sub5_desc:
      " We take reasonable steps to protect personal information from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. We also have policies and procedures in place to ensure that your personal information is handled and stored securely.  ",
    sub6: "6.	Changes to Privacy Policy:  ",
    sub6_desc:
      " We may update this privacy policy from time to time. If we make any changes to this policy, we will post the updated policy on our website. ",
    sub7: "Contact Us:",
    sub7_desc:
      "If you have any questions or concerns regarding this privacy policy, please contact us.  ",
    li1: "Trustline Digital Ventures Marketing Management CO. LLC",
    li2: "Unit 404, Nastaran Tower, Jaddaf Waterfront, Al Jaddaf, Dubai, UAE",
    li3_2: "+971 58 192 0960",
    li3: "+9714 397 8705",
    li4: "hello@trustlinedigital.com",
  },
];
export const tandc = [
  {
    Head: "TERMS & CONDITIONS",
    main_desc:
      "These terms and conditions (the 'Terms') govern the use of the services provided by Trustline (the 'Company'), a company based in Dubai, UAE. By using our services, you agree to be bound by these Terms.  ",
    descs: [
      {
        desc: "We provide a range of digital marketing services, including but not limited to search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, website development, and email marketing. The specific services to be provided will be outlined in the agreement between the company and the client. ",
      },
      {
        desc: " In the event that the project includes Pay Per Click or other search engine placement services which require payments to third parties and are agreed with the Client prior to their Implementation, the Client shall provide Trustline with funds on account for any out of pocket expenses incurred by it in supplying the same. ",
      },
      {
        desc: "Trustline ONLY starts work on a project upon receipt of a down payment. Unless otherwise specified in writing, the client will always pay the remaining balance before going live. All our projects are ONLY made live upon clearance of all outstanding balances. If applicable, hosting and domain name registration fees must be paid yearly or monthly.",
      },
      {
        desc: "Trustline reserves the right to suspend or terminate services if payment is not received within the agreed-upon timeframe.",
      },
      {
        desc: "The client has the option to put the project on hold (for whatever reasons e.g. funds, APIs, etc.). However, the client must pay all outstanding balance payments. The maximum time to hold a project is 6 months. Trustline doesn’t take any responsibility for technology change relevant to the client’s project during the period when the project is being held. The client will have to cover the relevant changes in technology (if any) at the time of work resumption on the project. ",
      },
      {
        desc: "Additional services come at additional fees, on a fee-for-service basis, or on an hourly or task rate. If clients require additional services, the correspondent fee is added to their invoice and is charged accordingly.",
      },
      { desc: "Delays in project completion may occur when:" },
    ],

    lists: [
      { li: " There is a delay in any payments." },
      {
        li: "The client requests further changes in the design, structure, or content of the project.",
      },
      {
        li: "The client purchases an additional service related to the project.",
      },
      {
        li: "There is a delay in the receipt of any materials from the client.",
      },
    ],

    desc2: [
      {
        desc: "Services or part of services (i.e. elements of a project) that have been approved by the client are considered as services with which the client is 100% satisfied. Any further work on these services (elements of the project) will be invoiced accordingly.",
      },
      {
        desc: "The Works provided by us shall be for the use and benefit of the Client and may not be supplied or passed on to any person without our approval.",
      },
      {
        desc: "Customer shall deliver all information necessary for Provider to perform the Services. In the unlikely event, that Customer cannot deliver the information to Provider, then Customer shall not hold Provider liable for failure of execution of Services.",
      },
      {
        desc: "The Proprietary Information is and shall remain the sole and exclusive property of Provider. Customer shall have only the limited rights with respect to the Proprietary Information expressly granted in this Agreement, and all rights not expressly granted by Provider are reserved. Customer agrees that only Provider shall have the right to alter, maintain, enhance or otherwise modify the Proprietary Information. Customer shall not disassemble, decompile, manipulate or reverse engineer the Proprietary Information and shall take all necessary steps to prevent such disassembly, decompiling, manipulation or reverse engineering of the Proprietary Information. Under no circumstances shall Customer sell, Services, publish, display, copy, distribute, or otherwise make available the Proprietary Information in any form or by any means, except as expressly permitted by this Agreement, including without limitation the transfer to a third party or, if not expressly prohibited by this Agreement, as allowed under the fair use provision of [Copyright Law]. Customer will take all reasonable steps, in accordance with the best industry practices, to protect the security of the Proprietary Information and to prevent unauthorized use or disclosure. Customer is responsible for all access to and use of the Proprietary Information by Customer’s employees or agents or by means of Customer’s equipment or Customer’s Provider usernames and passwords, whether or not Customer has knowledge of or authorizes such access or use.",
      },
      {
        desc: "Trustline retains ownership of all intellectual property rights, including but not limited to, any trademarks, logos, or copyrighted materials used in the course of providing services to the client. The client may use these materials for the purpose for which they were provided, but not for any other purpose without our express written permission. ",
      },
      {
        desc: "Trustline will keep all client information confidential and will not disclose it to any third party without the client's prior consent.",
      },
      {
        desc: "We won't be responsible for any damages, including direct, indirect, incidental, or consequential damages, that come from or are related to the services we provide. ",
      },
      {
        desc: "These Terms and any disputes arising out of or in connection with them will be governed by and construed in accordance with the laws of the United Arab Emirates.",
      },
      {
        desc: "We reserve the right to make changes to these Terms at any time and without notice. Any changes to these Terms will be binding on the client upon their acceptance of the services provided by us.",
      },
      {
        desc: "Either party has the right to terminate the agreement for any reason upon written notice to the other party. If the client ends the contract, the client is entitled to payment for all services done up to the date of termination. ",
      },
    ],
    sub7_desc:
      "If you have any questions about these Terms, please contact us.  ",
    li1: "Trustline Digital Ventures Marketing Management CO. LLC",
    li2: "Unit 404, Nastaran Tower, Jaddaf Waterfront, Al Jaddaf, Dubai, UAE",
    li3_2: "+971 58 192 0960",
    li3: "+9714 397 8705",
    li4: "hello@trustlinedigital.com",
    p: "By using our services, you acknowledge that you have read and understood these terms and conditions and agree to be bound by them.",
  },
];
