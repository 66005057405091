export const seo = [
  {
    main_heading: "Search Engine Optimization (SEO)",
    sub_heading1: "Looking for  ",
    sub_heading_grad: "Result-Driven",
    sub_heading1_2: "SEO services?",
    sub_heading2: "How SEO can boost your business?",
    sub_heading3: "SEO Services We Offer to Businesses in the UAE",
    sub_heading4: "Our Process",

    text1:
      "Start a conversation with our SEO specialists and get a personalized SEO strategy that will make your website stand out in the online marketplace and dominate the first page of search engines like Google.",
    text2:
      "Through Search Engine Optimization, we will help you increase website traffic, improve brand awareness, and generate more leads. It may take several weeks or months for a search to generate results organically but if you want lasting success, search engine optimization (SEO) is the way to go.",
    cover: "./Images/services/seo/Trustline-SEO-Services.svg",
    alt: "Maximize your online presence with our Dubai-based, results-driven SEO services.",
  },
];

export const seo_services = [
  {
    title: "Initial Analysis",
    desc: "Our team of SEO specialists will conduct a thorough analysis of your company’s website to understand how much work and time will be needed to get the search engine ranking you want.",
  },
  {
    title: "Keyword & SEO Content Plan",
    desc: "We’ll perform keyword research after the initial evaluation. It will help us identify keywords and optimize your site. We will also create a content plan and write content based on what your target audience is searching for on search engines.",
  },
  {
    title: "Technical Optimization",
    desc: "Technical SEO focuses more on the website architecture, looking at the back end to see how each page is set up. We will use a comprehensive approach and analyze your website to detect any weak spots and fix them.",
  },
  {
    title: "On-Page Optimization",
    desc: "To achieve a higher SEO score for your website, the design and content needs to be optimized. Our SEO services in Dubai and the UAE include on-page optimization, where we analyze website content, URLs, and meta tags to improve user experience and search engine visibility.",
  },
  {
    title: "Off-Page Optimization",
    desc: "Building high-quality backlinks is crucial to your website's reputation. We will assist you in obtaining backlinks from reputable sources. We will also look at your website to see if it has any unreliable backlinks, and remove them.",
  },
  {
    title: "Measuring SEO Performance",
    desc: "Even though SEO can take several months to produce results, we keep our clients informed every step of the way. We will provide a report on how well the SEO campaign is performing since its launch.",
  },
];
export const seo_process = [
  {
    desc: "Looking for Professional Search Engine Optimization Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your SEO needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const seo_process_cards = [
  {
    icon: "./Images/services/seo/Trustline-SEO-Audit.svg",
    alt: "Improve your website ranking with our comprehensive SEO audit. Get results-driven strategy to optimize your website.",
    title: "SEO Audit",
    bullets: [
      { list_item: " Page Indexing Check " },
      { list_item: "Google Mobile-friendly Test  " },
      { list_item: " Page Speed Check " },
      { list_item: " Google Search Console Site Error Review " },
    ],
  },
  {
    icon: "./Images/services/seo/Trustline-SEO-On-Page.svg",
    alt: "SEO on-page optimization plan to improve website ranking with expert strategy and analysis of keywords and content.",
    title: "On-page Plan",
    bullets: [
      { list_item: " Check website crawl-ability. " },
      { list_item: " URL, title, and meta description optimization. " },
      { list_item: "Keyword targeting.  " },
      { list_item: " Keyword-rich URLs. " },
    ],
  },
  {
    icon: "./Images/services/seo/Trustline-SEO-Off-Page.svg",
    alt: "Boost your website's visibility with our SEO off-page plan. With targeted link building, see growth in organic traffic.",
    title: "Off-page Plan",
    bullets: [
      { list_item: " Obtain Backlinks from Reliable Sources. " },
      { list_item: "Review Competitors' Backlinks.  " },
      { list_item: " Compare Page and URL Authority, and Rating. " },
    ],
  },
  {
    icon: "./Images/services/seo/Trustline-SEO-Evaluate.svg",
    alt: "We evaluate your SEO performance and provide comprehensive reporting services.",
    title: "Evaluate",
    bullets: [
      { list_item: " Review Performance. " },
      { list_item: "Optimize Based on Analytics.  " },
      { list_item: "Generate Reports.  " },
    ],
  },
];
