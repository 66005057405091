import React from "react";
import { useMediaQuery } from 'react-responsive';
import MostRecent from "./MostRecent";
import "swiper/swiper-bundle.css";
import {  Slider, SliderDesktop } from "./Slider";
import DefaultNav from "../../../components/navbar/defaultnav/DefaultNav";
import { BlogHelmet } from "../../../preclicktags/PreClick";
import GTM from '../../../googletagmanager/GTM';
import HomeNav from "../../../components/navbar/homenav/HomeNav";
const BlogHome = () => {
  const screenWidth = window.innerWidth;
  const isSmallDevice = screenWidth < 1200; // Define breakpoint for  width
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 992 });

  return (
    <>
    <GTM />
    <BlogHelmet/>
    {isDesktopOrLaptop ?   <DefaultNav /> : <HomeNav />}
    <div className="   background-baby section-padding-article">

      <MostRecent />
      <div>  {isSmallDevice ? (
         <Slider/>
      ) : (
       <SliderDesktop/>
      )}</div>
     </div>
 
      
    </>
  );
};

export default BlogHome;
