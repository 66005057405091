import React from "react";
import { wait } from "../../data/wait";
import "./wait.css";

const Wait = () => {
  return (
    <>
      <div className="  py-4  my-auto">
        {wait.map((val) => (
          <>
            <div className="wait-container">
              <h4 className="semibold manrope pb-3 section-padding">
                {val.title}
              </h4>
              <div className="  load-image wait-image mx-auto ">
                <img src={val.image} alt={val.alt} />
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Wait;
