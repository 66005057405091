import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import './bloghome.css'
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  FreeMode,
} from "swiper/core";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import Trending from "./categories/Trending";
import GraphicDesign from "./categories/GraphicDesign";
import EmailMarketing from "./categories/EmailMarketing";
import DisplayandVideo from "./categories/Display";
import SocialMedia from "./categories/SocialMedia";
import Web from './categories/Web'
import SEO from './categories/Seo'
import PPC from './categories/Ppc'
import ContentMarketing from './categories/Content'
import DigitalStrategy from './categories/DigitalStrategy'
import { useMediaQuery } from 'react-responsive';

SwiperCore.use([Navigation, FreeMode, Pagination, Autoplay]);

export const Slider = () => {
  const isMediumScreen = useMediaQuery({ minWidth: 576, maxWidth: 992 });
  const containerClass = isMediumScreen ? 'container' : 'container';
  const [swiper, setSwiper] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const categoryComponents = [
    { category: "Display and Video Advertising", component: <DisplayandVideo selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading}  /> },
    { category: "Graphic Design", component: <GraphicDesign selectedCategory={selectedCategory}  setLoading={setIsLoading} isLoading={isLoading} />  },
    { category: "Digital Strategy", component: <DigitalStrategy selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Search Engine Optimization", component: <SEO selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Email Marketing", component: <EmailMarketing selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Pay-per-click Advertising",component: < PPC selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Web Design & Development", component: <Web selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Content Marketing", component: <ContentMarketing selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Social Media Marketing", component: <SocialMedia selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
  ];
  useEffect(() => {
    console.log(`Category selected: ${selectedCategory}`);
  }, [selectedCategory]);
  
  useEffect(() => {
    console.log(`isLoading: ${isLoading}`);
  }, [isLoading]);
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredData = selectedCategory === "All"
  ? [{ category: "Trending", component: <Trending /> }, ...categoryComponents]
  : categoryComponents.filter((item) => item.category === selectedCategory);

  return (
    <>
      <section className="section-padding slider-nav-mobile">
      <div className={`  ${containerClass}`}>
      <div className="row ms-1">
       <Swiper
        slidesPerView={"auto"}
        spaceBetween={10}
        className="mySwiper swiper-slider-mobile"
        >
          <button> 

        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto "  >
          <div   className={selectedCategory === "All" ? "active" : ""}
                  onClick={() => handleCategoryClick("All")}>
            <h6 className="  my-auto"> All</h6>
            </div>
            </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto  "  >
          <div    className={selectedCategory === "Digital Strategy" ? "active" : ""}
                  onClick={() => handleCategoryClick("Digital Strategy")}>
            <h6 className="  my-auto"> Digital Strategy</h6>
            </div>
            </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto  "  >
          <div    className={selectedCategory === "Graphic Design" ? "active" : ""}
                  onClick={() => handleCategoryClick("Graphic Design")}>
            <h6 className="  my-auto">Graphic Design</h6>
            </div>
            </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto  "  >
          <div    className={selectedCategory === "Search Engine Optimization" ? "active" : ""}
                  onClick={() => handleCategoryClick("Search Engine Optimization")}>
            <h6 className="  my-auto">Search Engine Optimization</h6>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto  "  >
          <div    className={selectedCategory === "Email Marketing" ? "active" : ""}
                  onClick={() => handleCategoryClick("Email Marketing")}>
            <h6 className="  my-auto">Email Marketing</h6>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto "  >
          <div    className={selectedCategory === "Pay-per-click Advertising" ? "active" : ""}
                  onClick={() => handleCategoryClick("Pay-per-click Advertising")}>
            <h6 className="  my-auto">Pay-per-click Advertising</h6>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto "  >
          <div    className={selectedCategory === "Web Design & Development" ? "active" : ""}
                  onClick={() => handleCategoryClick("Web Design & Development")}>
            <h6 className="  my-auto">Web Design & Development</h6>
            </div>
            </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto  " >
          <div    className={selectedCategory === "Content Marketing" ? "active" : ""}
                  onClick={() => handleCategoryClick("Content Marketing")}>
            <h6 className="  my-auto">Content Marketing</h6>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto  "  >
          <div    className={selectedCategory === "Social Media Marketing" ? "active" : ""}
                  onClick={() => handleCategoryClick("Social Media Marketing")}>
            <h6 className="  my-auto">Social Media Marketing</h6>
            </div>
            </div>
        </SwiperSlide>
        
        <SwiperSlide className="swiper-slide-mobile">
          <div className=" p-3 my-auto mx-auto  "  >
          <div    className={selectedCategory === "Display and Video Advertising" ? "active" : ""}
                  onClick={() => handleCategoryClick("Display and Video Advertising")}>
            <h6 className="  my-auto"> Display and Video Advertising</h6>
            </div>
            </div>
        </SwiperSlide>
        </button>
 
    </Swiper>
      </div>
      {filteredData.map((item, index) => (
  <div key={index} className="category">
    {item.component}
  </div>
))}
        </div>
      </section>
    </>
  );
};

export const SliderDesktop = () =>{
  const [swiper, setSwiper] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
   const [isLoading, setIsLoading] = useState(false);

   const categoryComponents = [
    { category: "Display and Video Advertising", component: <DisplayandVideo selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading}  /> },
    { category: "Graphic Design", component: <GraphicDesign selectedCategory={selectedCategory}  setLoading={setIsLoading} isLoading={isLoading} />  },
    { category: "Digital Strategy", component: <DigitalStrategy selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Search Engine Optimization", component: <SEO selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Email Marketing", component: <EmailMarketing selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Pay-per-click Advertising",component: < PPC selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Web Design & Development", component: <Web selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Content Marketing", component: <ContentMarketing selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
    { category: "Social Media Marketing", component: <SocialMedia selectedCategory={selectedCategory} setLoading={setIsLoading} isLoading={isLoading} /> },
  ];
  useEffect(() => {
    console.log(`Category selected: ${selectedCategory}`);
  }, [selectedCategory]);

  useEffect(() => {
    console.log(`isLoading: ${isLoading}`);
  }, [isLoading]);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  }
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [selectedCategory, swiper]);

 
  const filteredData = selectedCategory === "All"
  ? [{ category: "Trending", component: <Trending /> }, ...categoryComponents.map(({category, component}) => ({category, component: React.cloneElement(component, { limit: 3 }) }))]
  : categoryComponents.filter((item) => item.category === selectedCategory); 
  return (
    <>
        <section className="section-padding slider-nav-desktop">
        <div className="container">
        <div className="row  d-flex flex-row">
        <div className="first my-auto">  
        <button onClick={goPrev} className="navigation-buttons p-3 my-auto">
        <IoChevronBackOutline />
        </button>
        </div>
        <div className="second">
        <Swiper
        slidesPerView={"auto"}
        spaceBetween={10}
        className="mySwiper my-auto swiper-desktop  px-2"
        onSwiper={setSwiper}
        slidesPerGroup={6}
        key={selectedCategory}
        >
        <button > 

         <SwiperSlide >
          <div className=" py-3 my-auto mx-auto text-category">
            <div className={selectedCategory === "All" ? "active" : ""}
                  onClick={() => handleCategoryClick("All")}>
            <h6 className="  my-auto py-1">All </h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide >
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div    className={selectedCategory === "Digital Strategy" ? "active" : ""}
                  onClick={() => handleCategoryClick("Digital Strategy")}>
            <h6 className="  my-auto py-1">Digital Strategy </h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide >
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div    className={selectedCategory === "Graphic Design" ? "active" : ""}
                  onClick={() => handleCategoryClick("Graphic Design")}>
            <h6 className="  my-auto py-1">Graphic Design </h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide >
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div    className={selectedCategory === "Search Engine Optimization" ? "active" : ""}
                  onClick={() => handleCategoryClick("Search Engine Optimization")}>
            <h6 className="  my-auto py-1">Search Engine Optimization </h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div    className={selectedCategory === "Email Marketing" ? "active" : ""}
                  onClick={() => handleCategoryClick("Email Marketing")}>
            <h6 className="  my-auto py-1">Email Marketing </h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div className={selectedCategory === "Pay-per-click Advertising" ? "active" : ""}
                  onClick={() => handleCategoryClick("Pay-per-click Advertising")}>
            <h6 className="  my-auto py-1">Pay-per-click Advertising </h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide >
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div className={selectedCategory === "Web Design & Development" ? "active" : ""}
                  onClick={() => handleCategoryClick("Web Design & Development")}>
            <h6 className="  my-auto py-1">Web Design & Development</h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div className={selectedCategory === "Content Marketing" ? "active" : ""}
                  onClick={() => handleCategoryClick("Content Marketing")}>
            <h6 className="  my-auto py-1">Content Marketing </h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide >
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div    className={selectedCategory === "Social Media Marketing" ? "active" : ""}
                  onClick={() => handleCategoryClick("Social Media Marketing")}>
            <h6 className="  my-auto py-1">Social Media Marketing</h6></div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" py-3 my-auto mx-auto text-category"  >
            <div    className={selectedCategory === "Display and Video Advertising" ? "active" : ""}
                  onClick={() => handleCategoryClick("Display and Video Advertising")}>
            <h6 className="  my-auto py-1">Display and Video Advertising </h6></div>
            </div>
        </SwiperSlide>
        </button>
   
    </Swiper>
    </div>
            <div  className="third my-auto"> 
            <button onClick={goNext} className="navigation-buttons p-3">
           <i> < IoChevronForwardOutline /> </i> 
              </button>
                </div>
      </div>

  {/*  {filteredData.map((item, index) => (
  <div key={index} className="category">
      {item.component}
  </div>
  ))} */}
{filteredData.map((item, index) => (
  <div key={index} className="category">
  {React.cloneElement(item.component, { selectedCategory, setIsLoading })}
  </div>
))}
        </div>
        {isLoading && <div className="loading-div" />}

      </section>
    </>
  );
}
 