import React from "react";

import { useState } from "react";
import {
  RiFacebookBoxLine,
  RiFacebookBoxFill,
  RiWhatsappLine,
  RiWhatsappFill,
  RiInstagramLine,
  RiInstagramFill,
  RiLinkedinBoxLine,
  RiLinkedinBoxFill,
} from "react-icons/ri";
import {TbBrandTiktok } from 'react-icons/tb'
 
import { MdEmail, MdMailOutline } from "react-icons/md";

export const Facebook = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickfacebook = () => {
    window.open("   https://www.facebook.com/Trustlinedigital/", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickfacebook}
        id="facebook"
        style={{ fontSize: "42px" }}
      >
        {isHovered ? <RiFacebookBoxFill /> : <RiFacebookBoxLine />}
      </div>
    </>
  );
};
export const Whatsapp = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickWhatsapp = () => {
    window.open("https://wa.me/message/FNSFJ5QTOGQPE1", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickWhatsapp}
        id="whatsapp"
        style={{ fontSize: "42px" }}
      >
        {isHovered ? <RiWhatsappFill /> : <RiWhatsappLine />}
      </div>
    </>
  );
};
export const Instagram = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickInstagram = () => {
    window.open(" https://www.instagram.com/trustlinedigital/", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickInstagram}
        id="instagram"
        style={{ fontSize: "42px" }}
      >
        {isHovered ? <RiInstagramFill /> : <RiInstagramLine />}
      </div>
    </>
  );
};
export const LinkedIn = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClicklinkedin = () => {
    window.open(
      "  https://www.linkedin.com/company/trustline-digital/",
      "_blank"
    );
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClicklinkedin}
        id="linkedin"
        style={{ fontSize: "42px" }}
      >
        {isHovered ? <RiLinkedinBoxFill /> : <RiLinkedinBoxLine />}
      </div>
    </>
  );
};
export const TikTok = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickfacebook = () => {
    window.open("   https://www.tiktok.com/@trustlinedigital?lang=en", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickfacebook}
        id="facebook"
        style={{ fontSize: "42px" }}
      >
        {isHovered ? <TbBrandTiktok /> : <TbBrandTiktok />}
      </div>
    </>
  );
};
export const CEOLinkedIn = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClicklinkedin = () => {
    window.open("    https://www.linkedin.com/in/ali-owji/", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClicklinkedin}
        id="linkedin"
      >
        {isHovered ? <RiLinkedinBoxLine /> : <RiLinkedinBoxFill />}
      </div>
    </>
  );
};
export const CEOMail = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="mail">
      <>
        <a
          href="mailto:ali.owji@trustline-ae.com"
          className="text-decoration-none"
        >
          <div
            role="button"
            style={{ color: "#353535" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? <MdMailOutline /> : <MdEmail />}
          </div>
        </a>
      </>
    </div>
  );
};
