import React from "react";
import { useMediaQuery } from "react-responsive";
import { qr_code } from "../../../data/Homedata";
const QrCode = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 991px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  let backgroundImgSrc = qr_code[0].background_pc;

  if (isDesktop) {
    backgroundImgSrc = qr_code[0].background_pc;
  } else if (isTablet) {
    backgroundImgSrc = qr_code[0].background_tab;
  } else if (isMobile) {
    backgroundImgSrc = qr_code[0].background_mob;
  }

  return (
    <section className="  section-padding background-baby">
      <div className="qr-code-container">
        <img
          src={backgroundImgSrc}
          alt={qr_code[0].alt}
          className="qr-code-img"
        />
        <div
          className={`qr-code-overlay bold row  ${
            isMobile || isTablet ? " bottom-right  " : "center"
          }`}
        >
          <div
            className="col-12 col-sm-8 col-lg-10 mx-lg-auto "
            style={{ color: "white" }}
          >
            <h2>{qr_code[0].title}</h2>
            <h5>{qr_code[0].sub}</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QrCode;
