import React from "react";
import "../buttonstyle.css";

export const MenuButton = ({ buttonText }) => {
  const [redirectTo, setRedirectTo] = React.useState(null);

  React.useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  const handleClick = () => {
    setRedirectTo("/contact-us");
  };

  return (
    <div>
      <>
        <button
          className="  menu-button   bold   my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text">
            <h5 className="my-auto">{buttonText}</h5>
          </text>
        </button>
      </>
    </div>
  );
};
