import React from "react";
import "../buttonstyle.css";

export const SecondaryButton = ({ buttonText, icon }) => {
  const [redirectTo, setRedirectTo] = React.useState(null);

  React.useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  const handleClick = () => {
    setRedirectTo("/contact-us");
  };

  return (
    <div>
      <>
        <button
          className=" secondary-button  bold p-2  my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text">{buttonText}</text>
        </button>
      </>
    </div>
  );
};
