export const contact = [
  {
    main_head: " Ready to Grow your Business in the UAE?",
    head: "Let’s Get in Touch",
    text: "Tell us what you need, we are always happy to help.    ",
    cover: "./Images/contact/Trustline-Contact-Us.svg",
    alt: "Contact us for help, we are always happy to assist.",
    head2: "Talk to Us",
    head3: "Visit Us",
    head4: "Follow us",
  },
];
