import React from "react";
import { empty } from "../../data/empty";
import { useState, useEffect } from "react";
import "./error.css";
import DefaultNav from "../../components/navbar/defaultnav/DefaultNav";
import GTM from "../../googletagmanager/GTM";
import { NotFoundHelmet } from "../../preclicktags/PreClick";
import notfound from '../../Images/not-found/Error_Desktop.svg'
const NotFound = () => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClickHome() {
    setRedirectTo("/");
  }
  function handleClickContact() {
    setRedirectTo("/contact-us");
  }
  return (
    <>
      <GTM />
      <NotFoundHelmet />
      <DefaultNav />
      <section className="not-found  ">
        <div className="container section-padding">
          <div className="row text-center   py-4  my-5">
            {empty.map((val) => (
              <>
                <h2 className="semibold manrope">{val.head}</h2>
                <h5 className="manrope pb-1 pb-md-3 semibold">{val.desc}</h5>
                <div className="not-found-image py-lg-5 py-3">
                  <img
             src={notfound}
                    alt={val.alt}
                  />
              
                </div>

                <div className="d-grid gap-2 d-sm-flex empty-states-buttons justify-content-sm-center">
                  <button
                    type="button"
                    className="go-back-home py-lg-3 py-2 btn-lg px-4  "
                    onClick={handleClickHome}
                    target="_top"
                  >
                    <h5 className="semibold manrope my-auto">
                      {val.buttontext1}
                    </h5>
                  </button>
                  <button
                    type="button"
                    className="  not-found-contact py-lg-3 py-2 btn-lg px-4"
                    onClick={handleClickContact}
                    target="_top"
                  >
                    <h5 className="semibold manrope my-auto">
                      {val.buttontext2}
                    </h5>
                  </button>
                </div>
               
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
