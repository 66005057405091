import React from "react";
import { reachus } from "../../../data/reach";
import "./location.css";
const Mail = () => {
  return (
    <div className="mail">
      {reachus.map((val, index) => (
         <React.Fragment key={index}>
          <a
            href="mailto:hello@trustlinedigital.com"
            className="text-decoration-none"
          >
            <div
              role="button"
              className="location text-wrap d-flex gap-3 flex-row"
            >
              <h3 className=" align-self-start d-flex">{val.icon_mail}</h3>
              <h5 className="bold   ">{val.mail}</h5>
            </div>
          </a>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Mail;
