import HomeHowWeDoIt from "../home/howwedoit/HomeHowWeDoIt";
import React from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "bootstrap/dist/css/bootstrap.min.css";

import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { services_cards } from "../../data/servicescards";
import "./services.css";
import { IoOpenOutline } from "react-icons/io5";
import { IoIosArrowDropright } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import ReadyToGrow from "../../components/banner/ReadyToGrow";
import { home_title } from "../../data/Homedata";
import DefaultNav from "../../components/navbar/defaultnav/DefaultNav";
import GTM from "../../googletagmanager/GTM";
import { ServicesHelmet } from "../../preclicktags/PreClick";
const ServicesScreen = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  return (
    <>
      <GTM />
      <ServicesHelmet />
      <DefaultNav />
      <section className="services-screen background-baby mt-5">
        <div className="container section-padding">
          {home_title.map((val, index) => (
            <div className="col-sm-12 bold text-center" key={index}>
              <h1 className="bold py-lg-3 py-2"> {val.services}</h1>
            </div>
          ))}
          {services_cards.map((val, index) => (
            <React.Fragment key={index}>
              <div className="row py-3">
                <div className="d-flex col-12  py-3 bold justify-content-start ">
                  <h3 className=" pe-2   jetspan bold">
                    {val.span1}
                    <text className=" " style={{ color: "#666666" }}>
                      {val.span2}
                    </text>
                  </h3>
                </div>
                <div>
                  <div className="row d-flex p-lg-3 px-0  ">
                    {val.cards.map((card, index2) => (
                      <React.Fragment key={index2}>
                        <div className="col-sm-4">
                          <div className="    p-1">
                            <div className="row d-flex flex-row ">
                              <div className="services-section-card ">
                                <a
                                  href={card.link}
                                  className="text-decoration-none "
                                  target="_top"
                                >
                                  <div className="row d-flex flex-row py-md-2 py-1   ">
                                    <div className="col-6 col-sm-12 justify-content-start d-flex my-auto flex-column">
                                      <h3 className="semibold py-xl-3 py-sm-2 py-4">
                                        {card.title}
                                      </h3>
                                    </div>
                                    <div className="col-6 col-sm-12 services-img d-flex justify-content-end pb-5 pb-sm-0  position-absolute  me-0">
                                      <img
                                        className=""
                                        src={card.img}
                                        alt={card.alt}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12  col-sm-12 ">
                                      <div className=" d-flex justify-content-end position-absolute find-out-more p-2 p-sm-3 ">
                                        {isDesktopOrLaptop ? (
                                          <Link
                                            className="services-link-pc text-decoration-none"
                                            to={card.link}
                                            target="_top"
                                          >
                                            <text className="bold">
                                              Find out more
                                              <i className="ps-1">
                                                <IoIosArrowDropright />
                                              </i>
                                            </text>
                                          </Link>
                                        ) : (
                                          <Link
                                            className="services-link text-decoration-none"
                                            to={card.link}
                                            target="_top"
                                          >
                                            <text className="bold">
                                              Find out more
                                              <i className="ps-1">
                                                <IoOpenOutline />
                                              </i>
                                            </text>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <ReadyToGrow />
        <HomeHowWeDoIt />
      </section>
    </>
  );
};

export default ServicesScreen;
