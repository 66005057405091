export const services_cards = [
  {
    id: 1,
    span1: " Want more leads?",
    span2: " Grow your business with us.",
    cards: [
      {
        id: 1,
        title: "Search Engine Optimization",
        img: "./Images/services/services-cards/Trustline-SEO-Services.svg",
        desc: "Maximize website traffic with our SEO team. Increase visibility, leads, and organic traffic by improving search engine rankings for your company in Dubai and the UAE.",
        link: "/search-engine-optimization",
        alt: "Boost Your Online Presence with Our Expert SEO Services.",
      },
      {
        id: 2,
        title: "Pay-per-click Advertising",
        img: "./Images/services/services-cards/PPC-Paid-Ads-Trustline.svg",
        desc: "Boost website traffic with our PPC services in Dubai and the UAE. We identify demographics, eliminate click fraud, and optimize your budget for improved leads and brand recognition.",
        link: "/pay-per-click-advertising",
        alt: "Our PPC Experts specialize in Paid Search Ads to drive more qualified traffic to your website.",
      },
      {
        id: 3,
        title: "Email Marketing",
        img: "./Images/services/services-cards/Trustline-Email-Marketing-Services.svg",
        desc: "Expand your reach and engage your target audience with our email marketing services in Dubai and the UAE. We create compelling content, manage email lists, and drive results for your desired outcomes.",
        link: "/email-marketing",
        alt: "High-performing email campaigns with our Email Marketing services. Boost engagement with targeted and personalized messages.",
      },
    ],
  },
  {
    id: 2,
    span1: "New or existing business in UAE? ",
    span2: "Take your brand to the next level with us.",
    cards: [
      {
        id: 4,
        title: "Digital Strategy",
        img: "./Images/services/services-cards/Trustline-Digital-Strategy-Services.svg",
        desc: "We develop and execute comprehensive digital strategies for businesses in Dubai and the UAE. Our team specializes in creating customized digital strategies to improve online presence and increase ROI.",
        link: "/digital-strategy",
        alt: "Maximize your digital potential with our expert Digital Strategy Services. Boost your online presence today.",
      },
      {
        id: 5,
        title: "Graphic Design",
        img: "./Images/services/services-cards/Trustline-Creative-Graphic-Design.svg",
        desc: "Our talented graphic designers will elevate your brand with stunning visuals, whether you are based in Dubai or anywhere in the UAE. From logo to marketing collateral, we bring your vision to life.",
        link: "/graphic-designing",
        alt: "Expert graphic design services from Trustline's talented team of creative designers.",
      },

      {
        id: 6,
        title: "Web Design & Development",
        img: "./Images/services/services-cards/Trustline-Web-Design-Development-Services.svg",
        desc: "We create stunning and effective websites for businesses in Dubai and the UAE. With a focus on responsive design & user experience, we ensure your website is both beautiful and functional. ",
        link: "/web-design-development",
        alt: "Trustline's website design and website development team offer stunning and efficient website solutions.",
      },
    ],
  },
  {
    id: 3,
    span1: " Want to boost your reach? ",
    span2: "Do it organically and effectively with us.",
    cards: [
      {
        id: 7,
        title: "Content Marketing",
        img: "./Images/services/services-cards/Trustline-Content-Marketing-Services.svg",
        desc: " Our expert team develops compelling content that drives engagement, builds brand awareness, generates leads, and fosters customer loyalty for businesses in Dubai and the UAE.",
        link: "/content-marketing",
        alt: "Our content marketing team creates high-quality content for your brand's success.",
      },
      {
        id: 8,
        title: "Display & Video Advertising",
        img: "./Images/services/services-cards/Trustline-Display-Video-Advertising-Services.svg",
        desc: " Stand out with our expertise in display and video advertising. We use data-driven insights to create compelling ads that drive engagement and conversions in Dubai and the UAE.",
        link: "/display-and-video-advertising",
        alt: "Our team of experts offer top-notch display ads & video advertising services. Boost your brand with our advertising solutions",
      },
      {
        id: 9,
        title: "Social Media Marketing",
        img: "./Images/services/services-cards/Trustline-Social-Media-Marketing.svg",
        desc: "Our expert social media marketing services help businesses in Dubai and the UAE expand their reach and make a significant impression online. We create & execute strategies that will help your business grow.",
        link: "/social-media-marketing",
        alt: "We offer comprehensive social media marketing services. Boost your online presence with our expert team.",
      },
    ],
  },
];
