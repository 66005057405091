import React from "react";
import { contact } from "../../../data/contact";
import "../../contact-us/contactus.css";
import ContactForm from "../../contact-us/ContactForm";
import {
  Facebook,
  Instagram,
  LinkedIn,
  TikTok,
  Whatsapp,
} from "../../../components/social-icons/SocialLinks";
import { Contact1 } from "../../../components/buttons/reach-us-buttons/Contact";
import Mail from "../../../components/buttons/reach-us-buttons/Mail";
import Location from "../../../components/buttons/reach-us-buttons/Location";
export const HomeContactUs = () => {
  return (
    <>
      <section className="Contactus background-baby section-padding   ">
        {contact.map((val, index) => (
          <React.Fragment key={index}>
            <div className="container  py-5 d-flex flex-column">
              <div className="row d-flex justify-content-between">
                <div className="col-lg-6 sol-sm-12 order2 mt-lg-0  mt-5">
                  <h1 className=" pb-lg-4 pb-2  bold showmeforpc">
                    {val.main_head}
                  </h1>
                  <div className="contact-box2   p-lg-5 p-3 py-5     col-lg-12 ">
                    <div className="contactus-page-img text-center mx-auto col-lg-8  ">
                      <img src={val.cover} alt={val.alt} />
                    </div>

                    <div className="py-lg-4 py-3 mx-auto row   contact-details">
                      <div className="  ">
                        <h3 className="bold">{val.head2}</h3>
                        <h4 className="bold py-1">
                          <Contact1 />
                        </h4>
                        <h4 className="bold py-1">
                          <Mail />
                        </h4>
                      </div>
                      <div className="col-lg-12 col-sm-6 col-12 ">
                        <h3 className="bold">{val.head3}</h3>
                        <h4 className="bold py-2">
                          <Location />
                        </h4>
                      </div>
                      <div className="col-lg-12 col-sm-6 col-12 ">
                        <h3 className="bold">{val.head4}</h3>
                        <div className="social-icons">
                          <div className="d-flex   flex-row     ">
                            <div className="px-2">
                              <i>
                                <Facebook />
                              </i>
                            </div>
                            <div className="px-2">
                              <i>
                                <Instagram />
                              </i>
                            </div>
                            <div className="px-2">
                              <i>
                                <LinkedIn />
                              </i>
                            </div>
                            <div className="px-2">
                              <i>
                                <Whatsapp />
                              </i>
                            </div>
                            <div className="px-2">
                              <i>
                                <TikTok />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6   my-auto  col-sm-12 order1 ">
                  <div className="  contact-box2 p-lg-5 p-3 py-5   ">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </section>
    </>
  );
};
