import React from "react";

import { menu_button } from "../../data/buttons";
import { menupage, menupage_links, nav } from "../../data/nav";
import { BgAnimation } from "../../pages/home/hero/BgAnimation";
import { MenuButton } from "../buttons/menu-button/MenuButton";
import logowhite from '../../Images/logo/white/Trustline_Logo_White.svg';
import "./menupage.css";

import {
  Facebook,
  Instagram,
  LinkedIn,
  Whatsapp,
  TikTok
} from "../social-icons/SocialLinks";
const MenuPage = ({ toggleMenu }) => {
  const handleClickProp = (event) => {
    event.stopPropagation();
  };
  return (
    <div className="menu-page" onClick={toggleMenu}>
      <div className="container-fluid" onClick={handleClickProp}>
        {nav.map((val, index) => (
          <React.Fragment key={index}>
            {menupage.map((item, index2) => (
             <React.Fragment key={index2}>
                <div className="row d-flex thero ">
                  <div className="col-lg-8 leftside hidemefortablet position-relative">
                    <BgAnimation />
                    <div className="position-absolute top-50  start-20">
                      <div className="pb-xxl-3 menupage-image">
                        <img src={logowhite} alt={val.alt1} />
                      </div>

                      <h3 className="py-1 py-xxl-3">{item.text}</h3>
                      <p>{item.span1}</p>
                      <div className="phone2 d-flex flex-column my-auto col-12">
                        <div className=" d-flex gap-2 flex-row my-auto">
                          <p className=" my-auto">{item.phone_icon}</p>
                          <a
                            href="tel:+9714-397-8705"
                            className="text-decoration-none  my-auto "
                          >
                            <p className="getintouch-text my-auto">
                              {item.phone_number1}
                            </p>
                          </a>
                        </div>
                        <div className=" d-flex gap-2 pt-1 pt-xl-3 flex-row my-auto">
                          <p className="   my-auto">{item.phone_icon2}</p>
                          <a
                            href="tel: +971-58-192-0960"
                            className="text-decoration-none  "
                          >
                            <p className="getintouch-text  my-auto">
                              {item.phone_number2}
                            </p>
                          </a>
                        </div>
                      </div>
                      <p className="pt-xl-4 pt-4">{item.span2}</p>
                      <a
                        href="mailto:hello@trustlinedigital.com"
                        className="text-decoration-none"
                      >
                        <p className="  pb-xl-3 semibold">{item.email}</p>
                      </a>
                      <MenuButton buttonText={menu_button[0].buttonText} />
                      <h5 className="pt-3 pt-xxl-5">{item.connect}</h5>
                      <div className="d-flex gap-3 flex-row">
                        <Facebook />
                        <Instagram />
                        <LinkedIn />
                        <Whatsapp />
                        <TikTok/>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-auto col-12   rightside">
                    <div className="   my-auto">
                      <ul className="list-unstyled   text-center">
                        {menupage_links.map((link) => (
                          <a
                            href={link.linkto}
                            className="py-lg-3 py-2 py-sm-3"
                          >
                            <h2>{link.l1}</h2>
                          </a>
                        ))}
                      </ul>
                    </div>
                    <div className="showmeformobile   row  text-center mx-auto ">
                      <MenuButton buttonText={menu_button[0].buttonText} />
                      <div className="  mx-auto">
                        <h5 className="pt-5 pt-sm-4">{item.connect}</h5>
                        <div className="d-flex  justify-content-center gap-3 text-center social-icons-menu flex-row">
                          <Facebook />
                          <Instagram />
                          <LinkedIn />
                          <Whatsapp />
                          <TikTok/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MenuPage;
