import { RiPhoneLine } from "react-icons/ri";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { HiDevicePhoneMobile } from "react-icons/hi2";

export const reachus = [
  {
    icon_location: <HiOutlineLocationMarker />,
    address:
      "Unit 404, Nastaran Tower, Jaddaf Waterfront, Al Jaddaf, Dubai, UAE",

    icon_phone: <RiPhoneLine />,
    icon_phone2: <HiDevicePhoneMobile />,
    contact: "+9714 397 8705",
    contact2: " +971 58 192 0960",
    icon_mail: <HiOutlineMail />,
    mail: "hello@trustlinedigital.com",
  },
];
