import React from "react";
import { reachus } from "../../../data/reach";
import "./location.css";
export const Contact1 = () => {
  return (
    <div>
      {reachus.map((val, index) => (
  <React.Fragment key={index}>
          <div role="button" className=" d-flex  bold flex-column ">
            <div className="d-flex flex-row location pb-1 ">
              <h3 className="bold align-self-center   d-flex">
                {val.icon_phone2}
              </h3>
              <a href="tel:+971-58-192-0960" className="text-decoration-none ">
                <h5 className="bold  ps-2 ">{val.contact2} </h5>
              </a>
            </div>
            <div className="d-flex flex-row location pt-1">
              <h3 className="bold   align-self-center d-flex">
                {val.icon_phone}
              </h3>
              <a href="tel:+9714-397-8705 " className="text-decoration-none  ">
                <h5 className="bold  ps-2 ">{val.contact} </h5>
              </a>
            </div>
          </div>
          </React.Fragment>
      ))}
    </div>
  );
};
