import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ShareButton from "./ShareButton";
import { useParams } from 'react-router-dom';
export const AuthorProfile = ({ author, post, createdAt }) => {
  if (!author || (!post && !createdAt)) {
    return <div>Loading...</div>;
  }

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const parts = formattedDate.split(' ');
    const day = parts[1].replace(',', '');
    const month = parts[0];
    const year = parts[2];
    return `${day}. ${month}. ${year}`;
  };
  const displayDate = post ? post.createdAt : createdAt;
  return (
    <div>
      <div className="row d-flex flex-row justify-content-between">
            <div className="col-12 d-flex flex-row ">
              <div className="my-auto">
              <img src={`${process.env.REACT_APP_API_URL}/uploads/${author.profilePicture}`} alt={author.name} /> 
              </div>
              <div className="d-flex flex-column ps-3 my-auto">
                <small className="  semibold">{author.name}</small>
                <small className="  regular">{formatDate(new Date(displayDate))}</small>
              </div>
            </div>
      </div>
    </div>
  );
};

export const AuthorProfileShare = () => {
  const [post, setPost] = useState({});
    const [author, setAuthor] = useState({});
    const { id } = useParams();
    useEffect(() => {
      const fetchPost = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        setAuthor(data.author);
        setPost(data);
      };
      fetchPost();
    }, [id]);
  return (
    <div>
      <div className="row d-flex flex-row justify-content-between">
            <div className="col-10 col-sm-11 col-lg-11 d-flex flex-row  ">
              <AuthorProfile  author={author} post={post}/>
            </div>
            <div className="col-2 col-sm-1 col-lg-1 my-auto ps-lg-5"  >
              <div className='ps-lg-3'>
              <ShareButton />
              </div>
            </div>
      </div>
    </div>
  );
};
