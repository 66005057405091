import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthorProfile } from "../../article/components/AuthorProfile";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "../bloghome.css";
import { getCategoryName } from '../../utils/categoryUtils';
import { useMediaQuery } from 'react-responsive';
import { formatTitle } from '../../utils/formatUtils';

const EmailMarketing = ({ category, setLoading,  selectedCategory   }) => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts?category=${category}`);
        // Check if the response has data
        if (response.data && response.data.length > 0) {
          setPosts(response.data);
        } else {
          setPosts([{ _id: 'no-posts', title: 'No content available', category: 'email' }]);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchPosts();
  }, [category, setLoading]);

  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClick = post => {
    const postTitle = formatTitle(post.title);
    setRedirectTo(`/blog/${postTitle}/${post._id}`);
  };

  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };
  let charLimit;
  let titleCharLimit;
  if (isLargeScreen) {
    charLimit = 165;
    titleCharLimit = 25; 
  } else if (isMediumScreen) {
    charLimit = 100;
    titleCharLimit = 20;  
  } else if (isSmallScreen) {
    charLimit = 75;
    titleCharLimit = 18; 
  }
  const  emailPosts = posts.filter((post) => post.category === 'email');

  const postsToShow = selectedCategory === "All"
    ? emailPosts.slice(0, 3)
    : emailPosts;

  // If there are no posts to show, return null or the no content div
  if (postsToShow.length === 0) {
    if (selectedCategory === 'All') {
      return null;
    } else {
      return <div className="no-content-div"></div>;
    }
  }
 
  return (
    <>
           <div className="  mt-5 ">
        <div className="row">
          <h2 className="bold pb-3">Email Marketing
 </h2>
 {isLargeScreen ? (
          <div className="grid-container pb-4">
            {postsToShow.map((post, index) => (
                  <div 
                  onClick={() => handleClick(post)}
                  style={{ textDecoration:"none", color:"#404040", cursor: 'pointer'}}
                >  
                <div className="trending-card trending-img box-shadow-main-banner " key={post._id}>
         <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className="trending-card-img-display"/>
         <div className="trending-content p-3">
         <h5 className='bold'>{truncateText(post.title, titleCharLimit)}</h5>
           <h6 className="category-trending semibold ">{getCategoryName(post.category)}</h6>
           <p className="pb-sm-2 pb-0 ">{truncateText(post.mainParagraph, charLimit)}</p>
           <div className="row d-flex justify-content-between  author-content-sec">
               <div className="col-5 col-sm-6 col-md-7 col-xl-8 ">
                <AuthorProfile author={post.author} createdAt={post.createdAt} />
               </div>
               <div className="col-5 col-sm-5 col-md-5 col-xl-4   my-auto">
                 <button className="read-more-btn medium">
                   <small className='semibold'>Read More <i><AiOutlineArrowRight /></i></small> 
                 </button>
               </div>
         </div>
       </div>
     </div>
     </div>
            ))}
          </div>
        ) : (
 <Swiper
   
   slidesPerView={3}
   slidesPerGroup={1}

      breakpoints={{
    576: {
       slidesPerView: 1.8,
       spaceBetween:40,
       slidesPerGroup: 1,
     },
   480: {
       slidesPerView: 1.8,
       spaceBetween:40,
       slidesPerGroup: 1,
     },
     380: {
       slidesPerView: 1.4,
       spaceBetween:40,
       slidesPerGroup: 1,
     },
    319: {
       slidesPerView: 1.2,
       spaceBetween:40,
       slidesPerGroup: 1,
     },
     678: {
       slidesPerView: 2.1,
       spaceBetween:40,
       slidesPerGroup: 2,
     },
    991: {
       slidesPerView: 3,
       spaceBetween:15,
       slidesPerGroup: 2,
     },
    1024: {
       slidesPerView: 3,
       spaceBetween:10,
       slidesPerGroup: 2,
     },
     1200: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween:10
    },
   }}
 >
          {postsToShow.map((post, index) => (

          <SwiperSlide   className={`slide-container py-3 mx-2 mx-lg-1 mx-xl-0 ${index === 0 ? 'ms-3 ms-lg-0' : ''}`} 
            key={post._id}>  
                        <div 
                         onClick={() => handleClick(post)}
                         style={{ textDecoration:"none", color:"#404040", cursor: 'pointer'}}
                       >  
                       <div className="trending-card trending-img box-shadow-main-banner" key={post._id}>
                <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className="trending-card-img-display"/>
                <div className="trending-content p-3">
                <h5 className='bold'>{truncateText(post.title, titleCharLimit)}</h5>
                  <h6 className="category-trending semibold ">{getCategoryName(post.category)}</h6>
                  <p className="pb-sm-2 pb-0 ">{truncateText(post.mainParagraph, charLimit)}</p>
                  <div className="row d-flex justify-content-between  author-content-sec">
                      <div className="col-5 col-sm-6 col-md-7 col-xl-8 ">
                       <AuthorProfile author={post.author} createdAt={post.createdAt} />
                      </div>
                      <div className="col-5 col-sm-5 col-md-5 col-xl-4   my-auto">
                        <button className="read-more-btn medium">
                          <small className='semibold'>Read More <i><AiOutlineArrowRight /></i></small> 
                        </button>
                      </div>
                </div>
              </div>
            </div>
            </div>
            </SwiperSlide>
          ))}
            </Swiper>
                )}
        </div>
      </div>
    </>
  );
};

export default EmailMarketing;
