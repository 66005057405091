import React from "react";
import "../buttonstyle.css";
import { useState, useEffect } from "react";
export const NavButton = ({ buttonText, redirect }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick() {
    setRedirectTo(redirect);
  }

  return (
    <div>
      <>
        <button
          className="  nav-button hidemeformobile bold   my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text">{buttonText}</text>
        </button>
      </>
    </div>
  );
};
export const NavButtonWhite = ({ buttonText, redirect }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick() {
    setRedirectTo(redirect);
  }

  return (
    <div>
      <>
        <button
          className="  nav-button-white hidemeformobile bold   my-auto  "
          onClick={handleClick}
        >
          <text className="my-auto button-text">{buttonText}</text>
        </button>
      </>
    </div>
  );
};
