
import React from 'react'
import vector from '../../../../Images/article/Instagram_vector.svg'
import { BsInstagram } from "react-icons/bs";
import '../articlepage.css'
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
export const Instagram =() => {
  return(
      <div className='bottom-overlay bg-BlackTrans d-flex justify-content-center align-items-center w-100 position-absolute text-white'>
      <div className="d-flex gap-2">
        <h4 className='m-auto'><BsInstagram/></h4>
        <h4 className='m-auto'><img src={vector} alt="Instagram-logo" /></h4>
      </div>
        </div>
  )
}

export const InstaImageDesktop = ({ images, altforImages }) => {
  const handleClickInstagram = () => {
      window.open(" https://www.instagram.com/trustlinedigital/", "_blank");
    };
    console.log("altforImages:", altforImages);  

    if (!images.length) {
      return null;
    }

return (
  <>
        <div className="row row-cols-lg-3 ">
          {images.map((imageSrc, index ) => (
            <div key={index} className="col  custom-col px-lg-3">
              <div className='overlay-container ' role="button" onClick={handleClickInstagram}>
                <div className="position-relative">
                  <img src={imageSrc} alt={altforImages} title={altforImages}
                  className='h-100 w-100'
                  style={{objectFit:"cover", borderRadius:"1rem"}}/>
                  <Instagram/> 
                </div>
              </div>
            </div>
          ))}
        </div>
  </>
)
}
export const InstaImage = ({ images, altforImages }) => {
  const handleClickInstagram = () => {
      window.open("https://www.instagram.com/trustlinedigital/", "_blank");
    };
    if (!images.length) {
      return null;
    }
return (
<>
 
<div>
<Swiper
    spaceBetween={0}
    modules={[Pagination]}
    breakpoints={{
      0: {
        slidesPerView: 1.7,
      },
      480: {
        slidesPerView: 2.1,
      },
      545: {
        slidesPerView: 2.5,
        centeredSlides: false,
      },
      992: {
        slidesPerView: 3,
        centeredSlides: false,
      },
    }}
  >
    {images.map((imageSrc, index) => (
      <SwiperSlide key={index}>
         <div className='overlay-container  me-3' role="button" onClick={handleClickInstagram}>
                <div className="position-relative">
                  <img src={imageSrc} alt={altforImages} title={altforImages}
                   className='h-100 w-100'
                  style={{objectFit:"cover", borderRadius:"1rem"}}/>
                  <Instagram/> 
                </div>
              </div>
        
      </SwiperSlide>
    ))}
  </Swiper>
  </div>
 

</>
)
}