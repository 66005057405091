import React from "react";
import "../homescreen.css";
export const BgAnimation = () => {
  return (
    <>
      <div fluid>
        <div className=" Thero  ">
          {/* left*/}
          <div className="outerleft"> </div>
          <div className="left-circle"></div>
          {/*Right*/}
          <div className="outerright"></div>
          <div className="right-circle"></div>
          {/*Bottum*/}
          <div className="outerbottum"></div>
          {/*Moving Animation*/}
          <div className="moving"></div>
        </div>
      </div>
    </>
  );
};
