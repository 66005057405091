import React from "react";
import { about, our_values, about_letter } from "../../data/about";
import "./about.css";
import ReadyToGrow from "../../components/banner/ReadyToGrow";
import { AboutHelmet } from "../../preclicktags/PreClick";
import GTM from "../../googletagmanager/GTM";
import { useMediaQuery } from "react-responsive";
import {
  CEOLinkedIn,
  CEOMail,
} from "../../components/social-icons/SocialLinks";

import DefaultNav from "../../components/navbar/defaultnav/DefaultNav";

const AboutUsScreen = () => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const imageSrc = isMobile
    ? about_letter[0].imageMobile
    : about_letter[0].image;

  return (
    <>
      <GTM />
      <AboutHelmet />
      <DefaultNav />
      <section className="about mt-5 background-baby ">
        {about.map((val, index) => (
          <React.Fragment key={index}>
            <div className="  about-hero      ">
              <div className="container section-padding">
                <div className="row    p-lg-0 d-flex justify-content-between">
                  <div className="col-xl-5 col-md-12 col-12">
                    <h1 className="semibold manrope py-lg-3 py-2">
                      {val.heading}
                    </h1>
                    <h4 className="bold manrope ">
                      {val.t1}
                      <text className="pe-2">{val.t2}</text>
                      {val.t3}
                    </h4>
                    <h5 className="medium manrope mb-2 pt-lg-3 pt-2">
                      {val.desc1}
                    </h5>
                  </div>
                  <div className="col-xl-6 col-md-12 col-12 pb-sm-2 about-image">
                    <img
                      className="pt-lg-0 pt-3 pt-sm-5"
                      src={val.cover1}
                      alt={val.alt1}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="  about-hero2      ">
              <div className="container section-padding ">
                <div className="row   d-flex justify-content-between">
                  <div className="col-xl-6 col-md-10 col-10 py-sm-2 order2 about-image mx-auto">
                    <img
                      className=" pb-sm-3 pb-3 pb-lg-0  "
                      src={val.cover2}
                      alt={val.alt2}
                    />
                  </div>
                  <div className="col-xl-5 my-auto col-md-12 col-12 order1 py-sm-5 py-5 py-lg-0">
                    <h4 className="bold manrope ">{val.desc2}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="container section-padding background-baby">
              <div className="row  d-flex    justify-content-between">
                {about_letter.map((val, index2) => (
                  <React.Fragment key={index2}>
                    <div className="col-xl-5 col-lg-5 col-12">
                      <h2 className=" bold py-lg-2 manrope">{val.head}</h2>
                      <h5 className="medium pt-2">{val.text1}</h5>
                      <h5 className="medium pt-2">{val.text2}</h5>
                      <h5 className="medium pt-2">{val.text3}</h5>
                    </div>
                    <div className="col-xl-5 col-lg-6 py-lg-2 col-md-9 col-sm-11 col-11 my-auto  mx-auto">
                      <div className="ceo-image pt-sm-3 pt-1 pt-lg-0 position-relative  ">
                        <img src={imageSrc} alt={val.alt1} />
                      </div>
                      <div className="badge  p-lg-3 p-sm-2 p-1 position-absolute  ">
                        <div className="d-flex  text-start  justify-content-between gap-sm-2 gap-0 row px-2">
                          <div className="  col-5 my-auto ">
                            <h4 className="bold  ">{val.badge_title}</h4>
                            <h5 className="semibold">{val.badge_sub_title}</h5>
                            <div className="CEO_social text-decoration-none d-flex gap-3 flex-row">
                              <CEOLinkedIn />
                              <CEOMail />
                            </div>
                          </div>
                          <div className="col-4 col-md-3 my-auto badge-image">
                            <img src={val.badge} alt={val.alt2} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="pt-5">
              <ReadyToGrow />
            </div>
            <div className="container section-padding  ">
              <div className="row our-values">
                <h2 className="semibold manrope text-center ">Our Values</h2>
                <div className="row py-lg-5 py-sm-4 ">
                  {our_values.map((val, index3) => (
                    <React.Fragment key={index3}>
                      <div className="col-12 col-sm-6 col-lg-4  text-center">
                        <div className="our-values-icon mx-auto py-3">
                          <img src={val.icon} alt={val.alt} />
                        </div>

                        <h5 className="semibold manrope">{val.title}</h5>
                        <p className="medium">{val.desc}</p>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </section>
    </>
  );
};

export default AboutUsScreen;
