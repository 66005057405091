export const ppc = [
  {
    main_heading: "Pay-per-click Advertising ",
    sub_heading1: "Showcase ads ",
    sub_heading_grad: "tailored ",
    sub_heading1_2: "to your target audience ",
    sub_heading2: " How PPC Advertising can boost your business?",
    sub_heading3:
      "Pay-per-click Advertising Services We Offer to Businesses in the UAE ",
    sub_heading4: "Our Process ",
    text1:
      "With our PPC services in Dubai and the UAE, we target your audience with tailored ads based on their specific searches. If you advertise on a search engine like Google, your target audience will likely be people who are already interested in what you have to offer. ",
    text2:
      "When users type a question into a search engine and your brand appears as the answer, this is a strong interaction. You are the answer to what they want, and as a result, when someone is actively looking and they choose you as the solution, they are very likely to act and do business with you following a search. A search engine like Google is a great channel for both B2B and B2C businesses because people use it to find things and get the information they need to make decisions.",
    bullets: [
      { list_item: "It brings more leads to your business." },
      { list_item: "It improves your brand recognition." },
      { list_item: "It provides enhanced targeting." },
      {
        list_item: "Useful for engaging both current and potential consumers.",
      },
    ],
    cover: "./Images/services/ppc/Trustline-PPC-Advertising.svg",
    alt: "Boost online visibility with our PPC advertising services. Drive traffic and get ROI with our proven strategies.",
  },
];
export const ppc_services = [
  {
    title: "Fraudulent Clicks Detection",
    desc: "We prevent fraudulent clicks that could drain a client's advertising budget by identifying and removing suspicious users. Our clients are likely to save a lot of money with this technology, and it ensures that the budget is being used efficiently.",
  },
  {
    title: "Keyword Research ",
    desc: " We'll make sure your PPC campaign succeeds by conducting extensive keyword research. When running a paid search campaign, keyword research is the single most important step. Your ad will only appear in front of people who are actively looking for it on a search engine.",
  },
  {
    title: " Negative Keyword Research",
    desc: " We will use a negative keyword strategy to help you save money on unnecessary clicks. This method involves blocking specific terms so that your ad does not appear when people search for them.",
  },
  {
    title: "Campaign Creation ",
    desc: " Our data-driven approach to PPC campaign creation sets us apart. We'll craft compelling ad copy and leverage advanced ad extensions to make ads stand out and drive clicks. With our expertise in PPC campaign creation, your campaign will deliver the results you need to succeed.",
  },
  {
    title: "Measuring Results & Optimizing Campaign ",
    desc: "Keeping analytics in mind, we are going to work on optimizing your campaign. Our company uses advanced measurement tactics to gain deeper insights. We will also keep optimizing keywords by analyzing which ones are performing well.    ",
  },
];
export const ppc_process = [
  {
    desc: "Looking for Professional Pay-per-click Advertising Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your PPC Advertising needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const ppc_process_cards = [
  {
    icon: "./Images/services/ppc/Trustline-PPC-Research.svg",
    alt: "Discover valuable insights with our detailed research services including client, competitor, audience, and keyword research.",
    title: "Research",
    bullets: [
      { list_item: "Comprehensive Client Research  " },
      { list_item: "Competitor Research  " },
      { list_item: " Target Audience Research " },
      { list_item: "Keyword Research  " },
    ],
  },
  {
    icon: "./Images/services/ppc/Trustline-PPC-Development.svg",
    alt: "We develop campaign strategies, set goals, measure performance, and manage budgets.",
    title: "Development",
    bullets: [
      { list_item: "Develop Campaign Strategy  " },
      { list_item: "Set Campaign Objectives  " },
      { list_item: "Establish KPI’s  " },
      { list_item: "Campaign Budgeting  " },
    ],
  },
  {
    icon: "./Images/services/ppc/Trustline-PPC-Campaign-Launch.svg",
    alt: "Launch your PPC campaign with professional content production and campaign setup.",
    title: "Launch",
    bullets: [
      { list_item: "Content Production  " },
      { list_item: "Set Up Campaign  " },
      { list_item: "Ensure Google-compliant Campaign  " },
      { list_item: "Campaign Launch  " },
    ],
  },
  {
    icon: "./Images/services/ppc/Trustline-PPC-Optimize.svg",
    alt: "Campaign optimization through review of analytics, advanced bid adjustments, and detailed reporting.",
    title: "Optimize ",
    bullets: [
      { list_item: "Review Analytics  " },
      { list_item: "Optimize Campaign  " },
      { list_item: " Advanced Bid Adjustments " },
      { list_item: "Reporting  " },
    ],
  },
];
