import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import axios from 'axios';
import { formatTitle } from "../pages/blogs/utils/formatUtils";

export const HomeHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Result-Driven Digital Marketing in Dubai | Trustline"
    : "Digital Marketing Agency Dubai | Trustline";
  const description = isMobile
    ? "A Dubai-based digital marketing agency specialized in data-driven solutions that help businesses achieve their goals."
    : "Ready to take your business to new heights? Our expert digital marketing services in Dubai drive results. Partner with us for your digital marketing needs!";
    
    const url = "https://www.trustlinedigital.com";  // Canonical URL

   
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      " https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
       <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const AboutHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "About Us | Digital Marketing Agency | Trustline"
    : "About Us | Digital Marketing Agency | Trustline";
  const description = isMobile
    ? "We are a leading digital marketing agency in Dubai, UAE. Transform your brand today with our data-driven insights!"
    : "Your gateway to quantitative marketing! We deliver digital marketing services that drive growth, security, and success for all UAE-based businesses.";
 
  const url =  "https://www.trustlinedigital.com/about";
  
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/about",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/about/Trustline-About-Digital-Marketing.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const ContactHelmet = () => {
  const title = "Contact Us | Digital Marketing Agency Dubai | Trustline";
  const description =
    "Contact us at 058-192-0960 & let us help you identify gaps in the market and thrive in the digital marketing space.";
    const url = "https://www.trustlinedigital.com/contact-us"
   
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/contact-us",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/contact/Trustline-Contact-Us.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const ServicesHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Expert Digital Marketing Services in Dubai | Trustline"
    : "Best Digital Marketing Services in Dubai | Trustline";
  const description = isMobile
    ? "We offer Digital Marketing Services in Dubai. PPC, SEO, Content Marketing, Graphic Design & more. Contact us today!"
    : "We provide Expert Digital Marketing Services in Dubai. PPC - Content Marketing - Graphic Design - Web development and more. Contact us to find out more! ";
 
 const url =   "https://www.trustlinedigital.com/services"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/services",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/home/we-do/Trustline-Digital-Marketing-Services.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const ThankyouHelmet = () => {
  const title = "Your Success is Our Priority | Trustline";
  const description =
    "Our team is committed to helping you succeed! Thank you for choosing us as your partner in growth.";

 const url =    "https://www.trustlinedigital.com/thankyou"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/thankyou",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/thankyou/Trustline-Thank-You-after.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const SEOHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Top SEO Agency Dubai | Boost Organic Website Traffic"
    : "Top SEO Agency Dubai | Boost Organic Website Traffic ";
  const description = isMobile
    ? "We help your website rank higher. Speak to our SEO specialists today & boost your online visibility with us."
    : "Our Dubai-based SEO agency offers tailored solutions to boost online visibility. From keyword research to technical SEO, we help your website rank higher!";
 const url =   "https://www.trustlinedigital.com/search-engine-optimization"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/search-engine-optimization",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/seo/Trustline-SEO-Services.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const PPCHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "PPC Services in Dubai | Drive Traffic, Leads, and Sales"
    : "Pay-Per-Click Advertising Services | PPC Experts Dubai ";
  const description = isMobile
    ? "Our tailored PPC services in Dubai drive traffic, leads, and sales for your business. Contact us today to maximize ROI. "
    : "Looking for effective PPC management in Dubai? Our experts provide tailored solutions to drive traffic, leads, and sales. Get results for your business now.";
 const url =    "https://www.trustlinedigital.com/pay-per-click-advertising"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/pay-per-click-advertising",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/ppc/Trustline-PPC-Advertising.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const EmailHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Effective Email Marketing Services in Dubai | Trustline"
    : "Effective Email Marketing Services in Dubai | Trustline";
  const description = isMobile
    ? "Drive conversions and ROI with our expert email marketing services in Dubai. Speak to our specialists today!"
    : "	Looking for a reliable email marketing agency in Dubai? We help you create & implement effective email campaigns that drive conversions & boost your ROI.";
 const url =   "https://www.trustlinedigital.com/email-marketing"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/email-marketing",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/email/Trustline-Email-Marketing-Services.svg ",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const ContentMarketingHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Top Content Marketing Services in Dubai | Trustline"
    : "Best Content Marketing Agency Dubai | Trustline";
  const description = isMobile
    ? "We offer expert content marketing strategies in Dubai, that drive engagement & conversions. Contact us & find out more. "
    : "Get your brand noticed with our content marketing services in Dubai. Improve your business performance by creating engaging and memorable content.";

 const url =   "https://www.trustlinedigital.com/content-marketing"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/content-marketing",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/content-marketing/Trustline-Content-Marketing.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const WebHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Web Design and Development Services in Dubai"
    : "Web Design and Development Services in Dubai";
  const description = isMobile
    ? "Our web design and development team create efficient and web analytics-friendly websites for companies in Dubai."
    : "We offer web design and development services in Dubai, creating unique and responsive websites that drive results. Contact us today to get started!";

 const url =   "https://www.trustlinedigital.com/web-design-development"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/web-design-development",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/web/Trustline-Web-Design-Development.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const DigitalStrategyHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Digital Marketing Strategy Services in Dubai | Trustline"
    : "Digital Marketing Strategy Services in Dubai | Trustline";
  const description = isMobile
    ? "Set measurable goals and KPI's with our digital marketing strategy services in Dubai. Consult our experts today!"
    : "Our digital marketing strategy services in Dubai help you create an effective plan to reach your audience and achieve your business goals. Contact us today!";

 const url =   "https://www.trustlinedigital.com/digital-strategy"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/digital-strategy",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/digital-strategy/Trustline-Digital-Strategy-Services.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const DisplayVideoHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Display Advertising Services in Dubai | Trustline"
    : "Expert Display Advertising Services in Dubai | Trustline";
  const description = isMobile
    ? "Expert Display Advertising Services in Dubai. Reach your audience with Google display ads & engaging video content."
    : "With our Display Advertising services in Dubai, you can create compelling Display Ads such as Google Display Ads & captivating video content. Contact Us!";
 const url =    "https://www.trustlinedigital.com/display-and-video-advertising"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/display-and-video-advertising",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/display-video/Trustline-Display-Video-Advertsing.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const GraphicDesignHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Professional Graphic Design Services Dubai | Trustline"
    : "Top Graphic Design Services in Dubai | Trustline";
  const description = isMobile
    ? "We offer professional graphic design services in Dubai that help businesses create visually appealing and engaging marketing materials. Contact us today!"
    : "Our team of expert in-house designers create visually stunning designs to help your brand stand out. Contact us now!";
 const url =    "https://www.trustlinedigital.com/graphic-designing"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/graphic-designing",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/graphic/Trustline-Graphic-Design.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const SocialMediaHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Social Media Marketing Dubai | Boost Online Presence"
    : "Social Media Marketing in Dubai | Increase Your Reach";
  const description = isMobile
    ? "Grow your brand's social presence in Dubai. Consult with our experts & boost your social media engagement."
    : "Grow your brand's social presence in Dubai. Our talented team will develop methods to boost your social media engagement and business leads. Call us today!";
 const url =    "https://www.trustlinedigital.com/social-media-marketing"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/social-media-marketing",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/services/smm/Trustline-Social-Media-Marketing.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const CookieHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Cookies Policy | Trustline"
    : "Cookies Policy | Trustline";
  const description = isMobile
    ? "By using our website, you acknowledge and consent to our use of cookies as outlined in this Cookie Policy."
    : "By using our website, you acknowledge and consent to our use of cookies as outlined in this Cookie Policy.";
 const url =    "https://www.trustlinedigital.com/cookie-policy"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/cookie-policy",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/cookie/Trustline-Digital-Cookie-Policy.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const PrivacyHelmet = () => {
  const title = "Privacy Policy | Trustline";

  const description =
    "These terms & conditions govern the use of services provided by us. If you have any questions, please contact us.";
    const url = " https://www.trustlinedigital.com/privacy-policy"
  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
    </Helmet>
  );
};
export const TandCHelmet = () => {
  const title = "Terms & Conditions | Trustline";

  const description =
    "These terms & conditions govern the use of services provided by us. If you have any questions, please contact us.";
const url = " https://www.trustlinedigital.com/terms-and-conditions"
  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
    </Helmet>
  );
};
export const SitemapHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Site Map | Digital Marketing Company Dubai | Trustline"
    : "Site Map | Digital Marketing Company Dubai | Trustline";
  const description = isMobile
    ? "Trustline Digital Marketing Site Map - Navigate through our website to explore our range of services."
    : "Trustline Digital Marketing Sitemap - Navigate through our website to explore our range of services, including PPC, Content Marketing, SEO, and more.  ";
 const url =   "https://www.trustlinedigital.com/sitemap"
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/sitemap",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/home/we-do/Trustline-Digital-Marketing-Services.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      "  https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const NotFoundHelmet = () => {
  const title = "Error 404 . Page Not Found";
  const description =
    "Oops! Page Not Found. Please check the URL or contact our team to help you find your way.";
  return (
    <Helmet>
     <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
    </Helmet>
  );
};
export const BlogHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Insights & Tips for Digital Marketing in Dubai | Blog "
    : "Expert Insights & Strategies | Digital Marketing Blog";
  const description = isMobile
    ? "Stay ahead in the digital world with expert insights from Trustline Digital in Dubai. Explore our Blog Now! "
    : "Explore our blog for expert insights, strategies, & industry trends. Gain valuable knowledge to enhance online presence & drive business success!";

 const url =  "https://www.trustlinedigital.com/blogs" 
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinedigital.com/blogs",
    logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinedigital.com/Images/home/we-do/Trustline-Digital-Marketing-Services.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      "https://www.facebook.com/Trustlinedigital/",
      " https://www.linkedin.com/company/trustline-digital/",
      " https://www.instagram.com/trustlinedigital/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const  ArticleHelmet = ({ id }) => {
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        setPost(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPost();
  }, [id]);

  if (!post) {
   
    return null;
  }

   const description = post.metaDescription;
  const keywords = post.metaKeywords;
  const title = formatTitle(post.title);
  const url = `https://www.trustlinedigital.com/blog/${title}/${id}`;  
 
 
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    url,
    headline: title,
    description,
    datePublished: post.createdAt,
    dateModified: post.updatedAt || post.createdAt,
 
    publisher: {
      "@type": "Organization",
      name: "Trustline",  
      logo: "https://www.trustlinedigital.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    },
    mainEntityOfPage: url,
    image: `https://www.trustlinedigital.com/${post.mainBanner}`,  
  };

  return (
<Helmet>
 <title>{post.metaTitle}</title>
     <link rel="canonical" href={url} />  
  <meta name="description" data-react-helmet="true" content={post.metaDescription} />
  <meta name="keywords" content={post.metaKeywords} />
  <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
</Helmet>

  );
};