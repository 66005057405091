import React from "react";
import { useState } from "react";
import { Howwedoit, home_title } from "../../../data/Homedata";
import image1 from '../../../Images/home/ How-we-do-it/Company Green Icons/Trustline-Submit-Brief.svg';
import image2 from '../../../Images/home/ How-we-do-it/Spring Green Icons/Trustline-Send-Detailed-Brief.svg';
import image3 from '../../../Images/home/ How-we-do-it/Company Green Icons/Trustline-Review-Process.svg';
import image4 from '../../../Images/home/ How-we-do-it/Spring Green Icons/Trustline-Review-Feedback.svg';
import image5 from '../../../Images/home/ How-we-do-it/Company Green Icons/Trustline-Draft-Approve.svg';
import image6 from '../../../Images/home/ How-we-do-it/Spring Green Icons/Trustline-Approve-Services.svg';
import image7 from '../../../Images/home/ How-we-do-it/Company Green Icons/Trustline-Grow-Review.svg';
import image8 from '../../../Images/home/ How-we-do-it/Spring Green Icons/Trustline-Services-Grow.svg';
const images = [image1, image2, image3, image4, image5, image6, image7, image8];
const HomeHowWeDoIt = () => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };
  return (
    <>
      <section className="Home-how-we-do-it section-padding background-baby">
        <div className="container   ">
          <div className="row p-4">
            {home_title.map((val, index) => (
              <div className="col-sm-12 bold text-center" key={index}>
                <h2 className="bold py-lg-3 py-2"> {val.how_we_do_it}</h2>
              </div>
            ))}
          </div>

          <div className="row">
            {Howwedoit.map((val, index) => (
              <React.Fragment key={index}>
                <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-3  " >
                  <div
                    key={index}
                    className={`card2   h-100 position-relative ${
                      hoveredIndex === index ? "card2-hover" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="row   d-flex flex-row  mx-auto align-middle my-auto  ">
                      <div className="col-3 col-sm-12 my-auto ">
                        <div className="card-image d-flex justify-content-center py-sm-4   ">
                          
                        <img
              src={hoveredIndex === index ? images[index*2+1] : images[index*2]}
              alt={hoveredIndex === index ? val.alt2 : val.alt1}
            />
                        </div>
                      </div>
                      <div className="col-9 col-sm-11 justify-content-start pt-2 my-auto mx-auto medium d-flex flex-column">
                        <h4 className="semibold">{val.title}</h4>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeHowWeDoIt;
