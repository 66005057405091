import React from "react";
import "./defaultnav.css";
import { nav } from "../../../data/nav";
import { nav_button } from "../../../data/buttons";
import { NavButton } from "../../buttons/nav-button/NavButton";
import ToggleButton from "./ToggleButton";
import logo from '../../../Images/logo/green/Trustline-Green-Logo.svg';

const DefaultNav = () => {
  return (
    <>
      <div className="default background-baby">
        <nav className="navbar navbar-expand-lg  default-nav   fixed-top">
          <div className="container">
            {nav.map((val, index) => (
              <a className="navbar-brand" href="/" key={index}>
                <img src={logo} alt={val.alt1} />
              </a>
            ))}
            <div className="d-flex flex-row navbar-button-hide">
              <NavButton
                buttonText={nav_button[0].buttonText1}
                redirect="/contact-us"
              />
              <div className="ps-lg-5 ps-md-3 ps-0 my-auto">
                <ToggleButton />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default DefaultNav;
