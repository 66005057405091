import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCategoryName } from '../utils/categoryUtils';
import { AuthorProfile } from "../article/components/AuthorProfile";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./bloghome.css";
import { formatTitle } from '../utils/formatUtils';
 
const MostRecent = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 992 });
  const isDesktopOrLaptopSmall = useMediaQuery({ minWidth: 576, maxWidth: 992 });
  const containerClass = isDesktopOrLaptop ? 'container' : '';
  const containerClassSmall = isDesktopOrLaptopSmall ? 'container' : '';

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts`);
        const latestPosts = response.data.slice(0, 1);
        setRecentPosts(latestPosts);
      } catch (error) {
        console.error('Error fetching recent posts:', error);
      }
    };
    fetchRecentPosts();
  }, []);
  const [post, setPost] = useState({});
  const [author, setAuthor] = useState({});
  const { id } = useParams();
  useEffect(() => {
    const fetchPost = async () => {
      if (!id) return;   

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
      setAuthor(data.author);
      setPost(data);
    };
    fetchPost();
  }, [id]);
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };
  let charLimit;
  if (isLargeScreen) {
    charLimit = 300;
  } else if (isMediumScreen) {
    charLimit = 255;
  } else if (isSmallScreen) {
    charLimit = 200;
  }
  return (
    <>
      <article>
  
        <section className="most-recent">
          <div className={`pt-lg-5 overflow-hidden ${containerClass}`}>{/**continer */}
          {recentPosts.map((post, index) => (
              <React.Fragment key={index}>
                <div className="row"  key={post._id}> 
                  <div className="col-lg-6 col-12 d-flex justify-content-center"  >
                  <Link to={`/blog/${formatTitle(post.title)}/${post._id}`} style={{ textDecoration:"none"}} 
                  className='mx-auto   d-flex justify-content-center'>
                   <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText}  className="banner-img most-img " /> 
                   </Link>
                  </div>
                  <div className={`col-lg-6  col-12 px-3 px-sm-4 px-4 px-sm-5 px-md-5 px-lg-2 my-auto pt-4 pt-lg-0 ${containerClassSmall}`}>
                  <div className='container-most-recent'>
                    <h1 className="semibold">{post.title}</h1>
                    <h5 className="category-home py-2 semibold"> {getCategoryName(post.category)}</h5>
                    <p className="pb-xl-5">{truncateText(post.mainParagraph, charLimit)}</p>
                    <div className="row d-flex justify-content-between">
                    <div className="col-7 col-sm-8 col-md-9 col-lg-9">
                    <AuthorProfile author={post.author} createdAt={post.createdAt} />
                      </div>
                      <div className="col-4 col-sm-3 col-md-3 col-lg-3 my-auto">
                      <Link to={`/blog/${formatTitle(post.title)}/${post._id}`} style={{ textDecoration:"none", color:"#404040"}}>  
                        <button className="read-more-btn medium">
                         <p className='semibold'>Read More <AiOutlineArrowRight /></p> 
                        </button>
                        </Link>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </section>
      </article>
    </>
  );
};

export default MostRecent;
