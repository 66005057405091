import React from "react";
import { useState, useEffect } from "react";

import { wedo, wedo_animation } from "../../../data/Homedata";

import "../homescreen.css";
import { PrimaryButton } from "../../../components/buttons/primary-button/PrimaryButton";
import { primary } from "../../../data/buttons";

const WeDoHome = ({ targetDivRef }) => {
  const [wedoIndex, setWedoIndex] = useState(0);
  const [typing, setTyping] = useState(true);
  const [textToShow, setTextToShow] = useState("");
  const [startErasing, setStartErasing] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typing) {
        setTextToShow((text) => {
          if (text.length === wedo_animation[wedoIndex].length) {
            setTyping(false);
            setTimeout(() => setStartErasing(true), 1500);
          }
          return wedo_animation[wedoIndex].slice(0, text.length + 4);
        });
      } else {
        setTextToShow((text) => {
          if (text.length === 0) {
            setTyping(true);
            setStartErasing(false);
            setWedoIndex((index) =>
              index === wedo_animation.length - 1 ? 0 : index + 1
            );
          } else if (startErasing) {
            return text.slice(0, text.length - 8);
          }
          return text;
        });
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [wedo_animation, wedoIndex, typing, startErasing]);

  return (
    <div ref={targetDivRef}>
      <section className="we-do-section section-padding background-baby">
        {wedo.map((val, index) => (
          <div className="container  " key={index}>
            <div className="row  " id="#We-do-section">
              <div className="hero-section col-8   col-sm-12   col-md-12">
                <div className="hero-text d-flex flex-column flex-lg-row">
                  <span className="hero-text__static">
                    <h1 className="justify-content-start pe-2 we-do-main regular">
                      {val.mainheading}
                    </h1>
                  </span>
                  <span className="hero-text__changing ">
                    <h1 className="hero-letter">{textToShow}</h1>
                  </span>
                </div>
              </div>

              <div className="row pt-lg-2 pt-sm-4 light">
                <div className="col-12">
                  <h4>{val.desc}</h4>
                </div>
              </div>
            </div>

            <div className="row d-flex flex-row">
              <div className="col-12 col-md-12 col-lg-6 pt-lg-5 pt-0  ">
                <div className="py-3 pb-lg-3 pt-lg-0 d-flex semibold     ">
                  <h4 className="talkto">
                    {val.text1}
                    <text className="we-do-free ps-2">{val.text2}</text>
                  </h4>
                </div>

                <div className="col-lg-6 col-12 pb-4 py-2 justify-content-start">
                  <PrimaryButton
                    buttonText={primary[0].buttonText1}
                    icon={primary[0].icon}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 ">
                <div className="who-we-are-image pt-3 mx-auto">
                  <img src={val.img} alt=" Digital marketing expert services including SEO, PPC, graphic design, social media marketing, UI/UX and website design." />
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default WeDoHome;
