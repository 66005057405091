import React, { useRef } from "react";
import "./homescreen.css";
import HomeServices from "./services/HomeServices";
import HomeHowWeDoIt from "./howwedoit/HomeHowWeDoIt";
import HomeWhyChooseUs from "./whychooseus/HomeWhyChooseUs";
import ReadyToGrow from "../../components/banner/ReadyToGrow";
import HeroHome from "./hero/HeroHome";
import WeDoHome from "./wedo/WeDoHome";
import QrCode from "./ qr-code/QrCode";
import HomeNav from "../../components/navbar/homenav/HomeNav";
import { HomeContactUs } from "./contact-us/HomeContactUs";
import { HomeHelmet } from "../../preclicktags/PreClick";
import GTM from "../../googletagmanager/GTM";
export const HomeScreen = () => {
  const targetDivRef = useRef(null);
  return (
    <>
      <GTM />
      <HomeHelmet />
      <div className="Trustline  ">
        <HomeNav />
        <HeroHome targetDivRef={targetDivRef} />
        <WeDoHome targetDivRef={targetDivRef} />
        <HomeServices />
        <QrCode />
        <HomeHowWeDoIt />
        <HomeWhyChooseUs />
        <ReadyToGrow />
        <HomeContactUs />
      </div>
    </>
  );
};
