// categoryUtils.js
export const getCategoryName = (category) => {
    const categories = {
      seo: 'Search Engine Optimization',
      ppc: 'Pay-per-click Advertising',
      email: 'Email Marketing',
      digital: 'Digital Strategy',
      graphic: 'Graphic Design',
      web: 'Web Design & Development',
      content: 'Content Marketing',
      display: 'Display & Video Advertising',
      social: 'Social Media Marketing',
    };
    return categories[category] || 'Unknown';
  };
   