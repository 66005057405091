export const email = [
  {
    main_heading: " Email Marketing",
    sub_heading1: " Engage Your Audience with   ",
    sub_heading_grad: "Effective ",
    sub_heading1_2: "Email Campaigns  ",
    sub_heading2: "How Email Marketing can boost your business? ",
    sub_heading3:
      "Email Marketing Services We Offer to Businesses in the UAE  ",
    sub_heading4: "Our Process ",
    text1:
      "Email marketing was the first direct method of online communication with people. It has become an integral part of everyone's lives. People check their email multiple times every day on their phones, computers, and other devices. Email is something that everyone uses, so it is a great way to communicate with your customers. Email advertising is an excellent method for communicating with both current and potential clients. Email marketing methods like newsletters, renewal notices, and registration emails are used to get customers and keep them coming back. We provide the best email marketing services in Dubai and throughout the UAE.  ",

    bullets: [
      {
        list_item:
          "Get greater return on investment than any other marketing method.",
      },
      {
        list_item:
          "Three times more likely to result in sales than social media outlets.",
      },
      { list_item: "It targets a particular audience." },
      { list_item: "Establish a strong relationship with clients." },
      {
        list_item:
          "Select emails based on the stage of the customer's buyer journey.",
      },
      { list_item: "Promote the introduction of new items and announcements." },
      {
        list_item: "Useful for engaging both current and potential consumers.",
      },
    ],
    cover: "./Images/services/email/Trustline-Email-Marketing-Services.svg",
    alt: "Boost sales and conversions with our expert email marketing services. We help you reach your target audience effectively.",
  },
];

export const email_services = [
  {
    title: "Email Strategy ",
    desc: "We will create a strong email marketing strategy that reaches and connects with your target audience in a personalized and cost-effective way.",
  },
  {
    title: "Email List Growth & Management ",
    desc: "We will use techniques for growing and managing your email lists that will put you ahead of the competition and turn your email marketing program into one that generates a significant return on investment.",
  },
  {
    title: "Email Content & Design ",
    desc: "In any email marketing strategy, it is essential to know the significance of both content and design. We will conduct research to determine the preferred type of content for your ideal audience. We'll take it a step further by using A/B testing to see which content gets the best results.",
  },
  {
    title: " Email Campaign Creation",
    desc: "Our team of experts will develop an effective email marketing campaign for your business. Before sending your email, our team will test it to ensure a smooth campaign.",
  },
  {
    title: " Testing & Optimizing",
    desc: "We will optimize your email marketing efforts by consistently enhancing various features and metrics. We'll use the information we have and make your campaign as successful as possible.",
  },
];
export const email_process = [
  {
    desc: "Looking for Professional  Email Marketing Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your Email Marketing needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const email_process_cards = [
  {
    icon: "./Images/services/email/Trustline-Email-Marketing-Strategy.svg",
    alt: "Maximize your campaign success with our comprehensive client research, competitor analysis, KPIs, and budgeting",
    title: "Strategy",
    bullets: [
      { list_item: "Comprehensive Client Research  " },
      { list_item: "Set Campaign Objectives  " },
      { list_item: " Competitor Research " },
      { list_item: " Establish KPI’s & Set Campaign Budget " },
    ],
  },
  {
    icon: "./Images/services/email/Trustline-Email-Content.svg",
    alt: "Boost email engagement with our email content services. Make an impact with persuasive and informative messaging.",
    title: "Content",
    bullets: [
      { list_item: "Email Components  " },
      { list_item: "Subject Line  " },
      { list_item: "Email Copy & Design  " },
      { list_item: "Images  " },
    ],
  },
  {
    icon: "./Images/services/email/Trustline-Email-Launch.svg",
    alt: "Launch successful email campaigns with our expert email marketing services.",
    title: "Launch",
    bullets: [
      { list_item: "Implement A/B Testing  " },
      { list_item: "Set Up Email Marketing Campaign  " },
      { list_item: "Email Testing  " },
      { list_item: "Launch Campaign  " },
    ],
  },
  {
    icon: "./Images/services/email/Trustline-Email-Campaign-Optimize.svg",
    alt: "Monitor and optimize your email campaigns with us. We ensure to minimize bounces and unsubscribes. ",
    title: "Optimize",
    bullets: [
      { list_item: "Monitor Campaign Metrics  " },
      { list_item: "Optimize Campaign  " },
      { list_item: " Minimize Bounces " },
      { list_item: " Minimize Unsubscribes " },
    ],
  },
];
