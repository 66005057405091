import React from "react";
import { BannerButton } from "../buttons/banner-button/BannerButton";
import { banner_button } from "../../data/buttons";
import "./readytogrow.css";
import { banner } from "../../data/banner";

const ReadyToGrow = () => {
  return (
    <>
      <section className=" section-padding background-baby">
        {banner.map((val, index) => (
          <div key={index}
            className="container-fluid Ready-to-grow  d-flex flex-column justify-content-center  "
            style={{ backgroundImage: `url(${val.background})` }}
          >
            <div className="row mx-auto">
              <div className=" col bold mx-auto  text-center">
                <h2> {val.text}</h2>
              </div>
            </div>
            <div className="row mx-auto p-2 justify-content-center">
              <div className="col">
                <BannerButton
                  buttonText={banner_button[0].buttonText1}
                  icon1={banner_button[0].icon1}
                  icon2={banner_button[0].icon2}
                />
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default ReadyToGrow;
