export const digital_strategy = [
  {
    main_heading: "Digital Strategy  ",
    sub_heading1: "Improve your business   ",
    sub_heading_grad: "performance  ",
    sub_heading1_2: "with an",
    sub_heading_grad2: "effective",
    sub_heading1_3: "digital strategy",
    sub_heading2: "How a Digital Strategy can boost your business?  ",
    sub_heading3:
      "Digital Strategy Services We Offer to Businesses in the UAE   ",
    sub_heading4: "Our Process ",
    text1:
      " Utilizing digital technology and solutions is no longer an option in a world that is competitive and result-oriented. It is mandatory. Not only can it help optimize your existing business, but it can also reveal chances to adapt and alter the organization for long-term, sustainable growth. Setting goals, putting plans into action, and going after high-level business results are all parts of a digital strategy plan. ",
    text2:
      "We are experts in the areas of technology and data. Using our broad perspective and hands-on experience in the industry, we'll collaborate with you to investigate, create, and implement a digital strategy tailor-made for your company. This makes it possible to scale your responses to emerging challenges and distinguish your organization from the competition by developing a unique growth strategy based on data and technology.",
    bullets: [
      { list_item: "Enables you to get extensive business knowledge." },
      {
        list_item:
          "Offers a crystal-clear depiction of all essential resources.",
      },
      { list_item: "Reduces the likelihood of failure." },
      { list_item: "No opportunities are lost." },
      { list_item: "Easy to monitor return on investment." },
      { list_item: "Offers direction to your business" },
    ],
    cover:
      "./Images/services/digital-strategy/Trustline-Digital-Strategy-Services.svg",
    alt: "Transform your digital presence with our customized digital strategy services.",
  },
];

export const digital_strategy_services = [
  {
    title: "Digital Audit and Research ",
    desc: " Our team will conduct thorough market and client research, followed by a digital audit to determine each media channel's campaign value. Running audits before you begin developing your strategy will help you get a handle on past performance and know what channels you can leverage.",
  },
  {
    title: "Establish Objectives ",
    desc: "Our team conducts keyword research to find out the current levels of interest in your product or service, which will help us determine the best digital strategy goal for all businesses in Dubai and throughout the UAE. We'll look at where your ideal customers are in the buying cycle to figure out what the best goal is.",
  },
  {
    title: "Content Strategy ",
    desc: 'The term "content strategy" describes the plan for what will be published on certain channels. We will do research to gain an understanding of the types of content that are enjoyed by your target audience, and then we will generate content based on our findings from the research.',
  },
  {
    title: "Digital Media Strategy ",
    desc: "When crafting your media plan, we'll consider such factors as how we'll make the most of your paid activity, how we'll get your ads in front of the right people, and what kind of ad format will yield the best results.",
  },
  {
    title: "Creative Strategy ",
    desc: "The creative strategy is the most visual element, which helps communicate with your audience through digital platforms. We will establish your brand's tone and style based on the content your demographic likes to see.",
  },
  {
    title: "Channel Strategy ",
    desc: "The channel strategy is understanding how you're going to use all the different digital platforms that are available to you. We will help you understand which platforms are best for your digital strategy, what formats we should use, and how we're going to utilize those platforms most effectively to target the audience engaging with it.",
  },
];
export const digital_strategy_process = [
  {
    desc: "Looking for Professional Digital Strategy Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your Digital Strategizing needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const digital_strategy_process_cards = [
  {
    icon: "./Images/services/digital-strategy/Trustline-Digital-Audit.svg",
    alt: " Get your digital strategy on track with our expert digital audit. Uncover potential growth opportunities",
    title: "Audit",
    bullets: [
      { list_item: "Detailed Market & Client Research  " },
      { list_item: " Competitor Research. " },
      { list_item: "Conduct Digital Audit.  " },
      { list_item: " Select Media Channels " },
    ],
  },
  {
    icon: "./Images/services/digital-strategy/Trustline-Digital-Strategy-Objective.svg",
    alt: "Maximize campaign success with our digital strategy services. Set objectives, KPIs, budgets, and media plans for optimal ROI.",
    title: "Objective",
    bullets: [
      { list_item: "Set Campaign Objectives  " },
      { list_item: "Establish KPI’s  " },
      { list_item: "Campaign Budgeting  " },
      { list_item: "Media Plan  " },
    ],
  },
  {
    icon: "./Images/services/digital-strategy/Trustline-Digital-Strategy.svg",
    alt: "We offer channel, content, and creative strategies with high-quality content production.",
    title: "Strategy",
    bullets: [
      { list_item: " Channel Strategy " },
      { list_item: "Content Strategy  " },
      { list_item: "Creative Strategy  " },
      { list_item: " Content Production " },
    ],
  },
  {
    icon: "./Images/services/digital-strategy/Trustline-Evaluation-Digital-Strategy.svg",
    alt: "We evaluate your campaign performance, optimize your campaigns, and generate reports with ease.",
    title: "Evaluate",
    bullets: [
      { list_item: "Measure Performance  " },
      { list_item: "Optimize Campaign  " },
      { list_item: "Generate Reports  " },
    ],
  },
];
