import React from "react";
import DefaultNav from "../../components/navbar/defaultnav/DefaultNav";

import { cookie } from "../../data/cookie";
import { CookieHelmet } from "../../preclicktags/PreClick";
import GTM from "../../googletagmanager/GTM";
const CookiePolicy = () => {
  const handleClick = () => {
    window.open("https://goo.gl/maps/S16bpXyuC3JnSk6x5", "_blank");
  };
  return (
    <>
      <GTM />
      <CookieHelmet />
      <DefaultNav />
      <section
        className="terms manrope mt-5 background-baby"
        style={{ color: "#1a1a1a" }}
      >
        <div className="container section-padding ">
          {cookie.map((val) => (
            <>
              <h1 className="bold text-center">{val.Head}</h1>
              <p className="medium justify-content-start pt-3">{val.desc1}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub1}</h5>
              <p className="medium">{val.sub1_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub2}</h5>
              <h6 className="semibold">{val.sub_semi}</h6>
              <p>
                <ul>
                  <li>{val.sub2_desc1}</li>
                  <li>{val.sub2_desc2}</li>
                  <li>{val.sub2_desc3}</li>
                </ul>
              </p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub3}</h5>
              <p className="medium">{val.sub3_desc1}</p>
              <p className="medium pt-1">{val.sub3_desc2}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub4}</h5>
              <p className="medium">{val.sub4_desc}</p>

              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub6}</h5>

              <p className="medium pt-1">{val.sub6_desc1} </p>
              <p className="medium pt-1">{val.sub6_desc2} </p>
              <p>
                <ul className="list-unstyled pb-5 w-lg-50 w-100 gap-1 semibold">
                  <li>
                    <b>{val.li1}</b>{" "}
                  </li>
                  <li
                    role="button"
                    onClick={handleClick}
                    style={{ color: "#084a44", textDecoration: "none" }}
                    className="py-1 py-md-2"
                  >
                    {val.li2}
                  </li>
                  <a
                    href="tel:+9714-397-8705"
                    style={{ color: "#084a44", textDecoration: "none" }}
                  >
                    <li className=" ">{val.li3}</li>
                  </a>
                  <a
                    href="tel:+971-58-192-0960"
                    style={{ color: "#084a44", textDecoration: "none" }}
                  >
                    <li className="py-1 py-md-2">{val.li3_2}</li>
                  </a>
                  <a
                    href="mailto:hello@trustlinegifts.com"
                    style={{ color: "#084a44", textDecoration: "none" }}
                  >
                    <li>{val.li4}</li>
                  </a>
                </ul>
              </p>
            </>
          ))}
        </div>
      </section>
    </>
  );
};

export default CookiePolicy;
