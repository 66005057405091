import React, { useState } from "react";
import MenuPage from "../../menu-page/MenuPage";
import "./defaultnav.css";
const ToggleButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="d-flex my-auto">
        <div
          className={`hamburger-menu   ${isOpen ? "is-active" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {isOpen && <MenuPage toggleMenu={toggleMenu} className="span-white" />}
    </>
  );
};

export default ToggleButton;
