import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthorProfile } from "../../article/components/AuthorProfile";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "../bloghome.css";
import { getCategoryName } from '../../utils/categoryUtils';
import { useMediaQuery } from 'react-responsive';
import { formatTitle } from '../../utils/formatUtils';

const Trending = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts`);
        const latestPosts = response.data.slice(0, 3);
        setRecentPosts(latestPosts);
      } catch (error) {
        console.error('Error fetching recent posts:', error);
      }
    };

    fetchRecentPosts();
  }, []);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClick = post => {
    const postTitle = formatTitle(post.title);
    setRedirectTo(`/blog/${postTitle}/${post._id}`);
  };

  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };
  let charLimit;
  let titleCharLimit;
  if (isLargeScreen) {
    charLimit = 90;
    titleCharLimit = 80; 
  } else if (isMediumScreen) {
    charLimit = 80;
    titleCharLimit = 50;  
  } else if (isSmallScreen) {
    charLimit = 75;
    titleCharLimit = 45; 
  }
  
 
  return (
    <>
      <div className="  mt-5 ">
        <div className="row">
          <h2 className="bold pb-3">Trending</h2>
         
          <Swiper
   
      slidesPerView={3}
         breakpoints={{
       576: {
          slidesPerView: 1.8,
          spaceBetween:40
        },
      480: {
          slidesPerView: 1.8,
          spaceBetween:40
        },
        380: {
          slidesPerView: 1.4,
          spaceBetween:40
        },
       319: {
          slidesPerView: 1.2,
          spaceBetween:40
        },
        678: {
          slidesPerView: 2.1,
          spaceBetween:40
        },
       991: {
          slidesPerView: 3,
          spaceBetween:15
         
        },
       1024: {
          slidesPerView: 3,
          spaceBetween:10
        },
      }}
    >
                {recentPosts.map((post, index) => (
                
                <SwiperSlide   className={`slide-container py-3 mx-2  mx-lg-1 mx-xl-0 ${index === 0 ? 'ms-3 ms-lg-0' : ''}`} 
                 key={post._id}>  
                 <div 
                  onClick={() => handleClick(post)}
                  style={{ textDecoration:"none", color:"#404040", cursor: 'pointer'}}
                >  
                <div className="trending-card trending-img box-shadow-main-banner  " key={post._id}>
                <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className="trending-card-img-display"/>
                <div className="trending-content p-3"> 
                <h5 className='bold'>{truncateText(post.title, titleCharLimit)}</h5>
                <h6 className="category-trending semibold ">{getCategoryName(post.category)}</h6>
                <p className="pb-sm-2 pb-0 ">{truncateText(post.mainParagraph, charLimit)}</p>
                <div className="row d-flex justify-content-between  author-content-sec">
                      <div className="col-5 col-sm-6 col-md-7 col-xl-8 ">
                       <AuthorProfile author={post.author} createdAt={post.createdAt} />
                      </div>
                      <div className="col-5 col-sm-5 col-md-5 col-xl-4 my-auto">
                        <button className="read-more-btn medium">
                          <small className='semibold'>Read More <i><AiOutlineArrowRight /></i></small> 
                        </button>
                      </div>
                </div>
              </div>
            </div>
            </div>
            </SwiperSlide>
       
          ))}
            </Swiper>
        </div>
      </div>
    </>
  );
};

export default Trending;
