export const about = [
  {
    heading: "Who Are We?",
    cover1: "./Images/about/Trustline-About-Digital-Marketing.svg",
    cover2: "./Images/about/Trustline-About-Page-Digital.svg",
    alt1: "In-house team of analytical thinkers delivering digital marketing results. We're your go-to agency for quantifiable success.  ",
    alt2: "Learn about our company's mission, and values. Get to know our team of experts & boost your online presence with us",
    t1: "We’re an in-house ",
    t2: "Quantitative",
    t3: "digital marketing team of highly analytical thinkers that deliver results.",
    desc1:
      "Welcome to Trustline Digital, your gateway to quantitative marketing! We are a Dubai-based digital marketing agency specializing in data-driven solutions that help businesses achieve their goals. Our team of professionals work in-house to ensure quality and security, with a passion for transparency and helping your business grow. We have access to all the resources, tools, and expertise necessary to deliver cutting-edge services, analysis, insights, and guaranteed results to our clients. Whether you're looking for comprehensive digital marketing support or targeted solutions to specific challenges, Trustline Digital has you covered. Let us help you identify gaps in the market and thrive in the digital marketing sphere.",
    desc2:
      " Empowering business success through our digital solutions, we are your gateway to success in the UAE. Transform your digital marketing strategy with our data-driven insights. ",
  },
];

export const about_letter = [
  {
    head: "Letter from the CEO",
    text1:
      "Coming from a Quantitative background and graduating as an Economist, I quickly realized a severe market gap in ethical 'Digital Marketing' practices and knowledge within the region. I intended to set up an organization that would allow businesses of all sizes to optimize their marketing budgets and increase their ROI’s and Reach optimally and with ease. At Trustline, our aim is to revolutionize and positively affect the economy and our customers' businesses by establishing a digital service ecosystem based on scientific and data-driven methodologies.",
    text2:
      "Our core Ethos is built around “Data-Driven Marketing”. In today’s Digital Ecosystem, Data is King, and Presence is not sufficient. It is absolutely vital to understand where your consumer stands on the buyer’s journey, their behavior patterns and hence targeting them efficiently using sophisticated analytical tools. Data in itself is not sufficient; you need to be able to tell a story around the vast majority of data collected and turn it into meaningful insight.",
    text3:
      "We are constantly reimagining what's possible in order to help us all do what we love in new ways. The same creativity that goes into our services extends to our practices, reinforcing our commitment to leaving the world a better place than we found it.",
    image: "./Images/about/Trustline-CEO-Letter.png",
    imageMobile: "./Images/about/Trustline-CEO-Letter-mobile.png",
    alt1: "CEO's vision: Bridging the gap in digital marketing practices for businesses to optimize budgets, increase ROI, and reach.",
    badge: "./Images/about/Trustline-DMI-Certification.png",
    alt2: "A DMI Pro certified digital marketing expert leading our team to success. Join us and benefit from his expertise.",
    badge_title: "Ali Owji",
    badge_sub_title: "CEO & Managing Director",
  },
];

export const our_values = [
  {
    icon: "./Images/about/Trustline-Data-Driven-Decision-Making.svg ",
    alt: "Empower your marketing strategy with data-driven decision making. Let our experts analyze your data for optimal results.",
    title: "Data-driven decision making",
    desc: " We believe in making informed decisions backed by data to deliver the best results for our clients. Our approach ensures that every decision is based on accurate insights and thorough analysis.",
  },
  {
    icon: "./Images/about/Trustline-Transparency-Values.svg",
    alt: "Digital marketing agency that values transparency. Our honest approach guarantees client satisfaction and long-term success. ",
    title: "Transparency",
    desc: " We believe in open and honest communication with our clients, ensuring trust and integrity throughout all our services.",
  },
  {
    icon: "./Images/about/Trustline-Results-Oriented-Approach.svg",
    alt: " Experience the impact of our results-oriented approach. Measurable outcomes guaranteed. Trust us for your marketing success.",
    title: "Results-oriented approach",
    desc: "We prioritize achieving tangible outcomes for our clients. We are focused on delivering measurable results, and we ensure that our clients see the impact of our work.",
  },
  {
    icon: "./Images/about/Trustline-Education-Learning.svg ",
    alt: "Trustline's commitment to continuous learning ensures up-to-date industry expertise. ",
    title: "Education",
    desc: " We are committed to continuous learning and staying up-to-date with the latest industry trends to provide the best solutions for our clients.",
  },
  {
    icon: "./Images/about/Trustline-Digital-Expertise.svg ",
    alt: "Expertise that delivers, our digital marketing team tailors effective solutions to meet your business needs. ",
    title: "Expertise",
    desc: " Our team’s extensive expertise in digital marketing allows us to deliver effective solutions tailored to your business needs.",
  },
  {
    icon: "./Images/about/Trustline-Proven-Track-Record.svg ",
    alt: "We elevate your brand's online success. Our targeted digital marketing strategies yield proven results for our clients. ",
    title: "Proven track record",
    desc: "We take pride in our ability to consistently deliver results for our clients through targeted digital marketing strategies.",
  },
];
