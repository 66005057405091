import { BsFileEarmarkRichtext } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import { HiOutlineCursorClick, HiOutlineVideoCamera } from "react-icons/hi";
import { MdOutlineMailOutline } from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";
import { RiCodeBoxLine } from "react-icons/ri";
import { TbDeviceAnalytics } from "react-icons/tb";
import { BiPen } from "react-icons/bi";

export const slides = [
  {
    url: "/search-engine-optimization",
    icon: <BiSearchAlt />,
    text: "Search Engine Optimization",
  },
  {
    url: "/pay-per-click-advertising",
    icon: <HiOutlineCursorClick />,
    text: "Pay-per-click Advertising",
  },
  {
    url: "/email-marketing",
    icon: <MdOutlineMailOutline />,
    text: "Email Marketing",
  },
  {
    url: "/digital-strategy",
    icon: <TbDeviceAnalytics />,
    text: "Digital Strategy",
  },
  {
    url: "/graphic-designing",
    icon: <BiPen />,
    text: "Graphic Designing",
  },

  {
    url: "/web-design-development",
    icon: <RiCodeBoxLine />,
    text: "Web Design & Development",
  },
  {
    url: "/content-marketing",
    icon: <BsFileEarmarkRichtext />,
    text: "Content Marketing",
  },
  {
    url: "/display-and-video-advertising",
    icon: <HiOutlineVideoCamera />,
    text: "Display and Video Advertising",
  },
  {
    url: "/social-media-marketing",
    icon: <TfiAnnouncement />,
    text: "Social Media Marketing",
  },
];
