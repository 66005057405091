import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './articlecss.css'
import { AuthorProfileShare } from './components/AuthorProfile';
import { RelatedArticle, RelatedArticleMobile } from './RelatedArticle';
 import { ArticleHelmet } from '../../../preclicktags/PreClick';
import GTM from '../../../googletagmanager/GTM';
import DefaultNav from '../../../components/navbar/defaultnav/DefaultNav';
import { useMediaQuery } from 'react-responsive';
import HomeNav from '../../../components/navbar/homenav/HomeNav';
import { InstaImage, InstaImageDesktop } from './components/InstaImage';
import Youtube from './components/Youtube';

const ArticlePage = () => {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 992 });
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const containerClass = isLargeScreen ? 'container' : '';

    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [post, setPost] = useState({});
    const { id } = useParams();
    const [relatedArticles, setRelatedArticles] = useState([]);
    const [author, setAuthor] = useState({});
    useEffect(() => {
      const fetchPost = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        setAuthor(data.author);
        setPost(data);
      };
      fetchPost();
    }, [id]);
    useEffect(() => {
      const fetchRelatedArticles = async () => {
        try {
          if (post.category) {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/category?category=${post.category}`);
            setRelatedArticles(data.filter((article) => article._id !== post._id));
          }
        } catch (error) {
          console.error('Failed to fetch related articles.', error);
        }
      };
      fetchRelatedArticles();
    }, [post.category]);

    if (!post) {
      return <div>Loading...</div>;
    }
  return (
              <> 
            <GTM />
            <ArticleHelmet id={id} />
            {isDesktopOrLaptop ?   <DefaultNav /> : <HomeNav />}
       
            <div className="   mt-xl-4 mt-xxl-5  section-padding-article overflow-hidden" >          
             <div className="row">
             <div className="col-lg-10 col-xxl-12 col-12 mx-auto   d-flex justify-content-center">
            <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className="banner-image-main border-rounded box-shadow-main-banner"/>
            </div>   
            </div>
                  <div className= 
                  {`  col-md-10 col-xxl-10 col-12 mx-auto big-screen-container ${containerClass}`}>
                  <div className="col-11 col-md-12 col-xl-12 pt-md-5 py-3 mx-auto ">
                  <h1 className="bold">{post.title}</h1>
                  </div>
                  <div className='col-11 col-md-12 col-xl-12 mx-auto'>
                   <AuthorProfileShare/>
                   <p className="pt-md-4 pt-3 medium">
                   {post.mainParagraph && post.mainParagraph.split("\n").map((line, lineIndex) => (
                     <React.Fragment key={lineIndex}>
                     <p className=" medium">{line}</p>
                   </React.Fragment>
                   ))}
                    </p>
                    {post.subParagraph && 
                      <p>
                        {post.subParagraph.split("\n").map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    }

                  </div>
                  {post.images && post.images.length > 0 && (
                  <div className='d-block d-lg-none ms-3 my-3'>
                  <InstaImage images= {post.images?.map((image, index) =>  `${process.env.REACT_APP_API_URL}/${image}`)}
                   altforImages={post.altTextSecondary} />
                </div>
                )}
                {post.images && post.images.length > 0 && (
    <div className='d-none d-lg-block my-3 my-lg-5'>
        <InstaImageDesktop 
            images={post.images.map((image) => `${process.env.REACT_APP_API_URL}/${image}`)} 
            altforImages={post.altTextSecondary} 
        />
    </div>
)}
 

<div className='col-11 col-md-12 col-xl-12 mx-auto' >
 
  {post.sections && post.sections.length > 0 && post.sections.map((section, index) => (
  <div key={index} className='w-100'> 
  <h4 className="text-start bold" >{section.subTitle}</h4>
  <p>
    {section.paragraph && section.paragraph.split("\n").map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line}
        <br />
      </React.Fragment>
    ))}
  </p>
  {section.sectionImage && 
    <div className=' '>
      <img src={`${process.env.REACT_APP_API_URL}/${section.sectionImage}`} alt={section.subTitle}
        className='w-100 my-3 banner-image h-52 object-fit-contain box-shadow-main-banner' style={{borderRadius:"16px"}}/>
    </div>
  }
  {section.youtubeLink && 
    <div className='w-100 my-3 my-lg-4 mb-lg-5'> 
      <Youtube videoLink={section.youtubeLink} />
    </div>
  }
</div>
))}
                  <AuthorProfileShare/>
                  <div className="py-md-4 py-2">
                    <hr />
                  </div>
                  <div className="">
                    <div className="text-start">
                      <h4 className="semibold py-2">Related articles</h4>
                    </div>
                    </div>
                    <div>
                  {isMobile ? (
                  <div> 
                  <RelatedArticleMobile articles={relatedArticles}/>
                 </div>
                 ) : (
                    <div>
                      <RelatedArticle articles={relatedArticles} />
                    </div>
                    )}
                   </div>
                  </div>


                </div>
              
              </div>
             
              </>
  )
}

export default ArticlePage
