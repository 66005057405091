 
 
import "./articlepage.css";
import { AuthorProfile } from "./components/AuthorProfile";
import { AiOutlineArrowRight } from "react-icons/ai";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getCategoryName } from '../utils/categoryUtils';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { formatTitle } from "../utils/formatUtils";
import { useLocation } from 'react-router-dom';

export const RelatedArticle = () => {
  const [post, setPost] = useState({});
  const [author, setAuthor] = useState({});
  const [articles, setArticles] = useState([]);
  const { id } = useParams();
 
  useEffect(() => {
    const fetchPostAndRelatedPosts = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
      setAuthor(data.author);
      setPost(data);
      const { data: relatedPosts } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/related?category=${data.category}&currentPostId=${id}`);
      setArticles(relatedPosts);
    };
    fetchPostAndRelatedPosts();
  }, [id]);

  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const isExtraLargeScreen = useMediaQuery({ minWidth: 1801 });
  const isLargeScreen = useMediaQuery({ minWidth: 1440, maxWidth:1800  });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1439 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  let charLimit;
  let titleCharLimit;
  if (isExtraLargeScreen) {
    charLimit = 205;
    titleCharLimit = 100; 
  }
  else if (isLargeScreen) {
    charLimit = 255;
    titleCharLimit = 55; 
  } else if (isMediumScreen) {
    charLimit = 80;
    titleCharLimit = 20;  
  } else if (isSmallScreen) {
    charLimit = 65;
    titleCharLimit = 18; 
  }

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); 

  const handleClick = article => {
    const articleTitle = formatTitle(article.title);
    setRedirectTo(`/blog/${articleTitle}/${article._id}`);
  };

  return (
    < > 
      {articles.map((article) => (
         <div className="py-2 py-xxl-3" key={article._id}> 
           <div className="related-article box-shadow-main-banner rounded-4" onClick={() => handleClick(article)}>
             <div className="row d-flex flex-row justify-content-between">
               <div className="col-5 related-img">
                 <img src={`${process.env.REACT_APP_API_URL}/${article.mainBanner}`} alt={article.altText}/>
               </div>
               <div className="col-7 py-3 my-auto pe-4">
                 <h5 className="bold">{truncateText(article.title, titleCharLimit)}</h5>
                 <p className="category-name semibold">{getCategoryName(article.category)}</p>
                 <p style={{ color: "#404040" }}>{truncateText(article.mainParagraph, charLimit)}</p>
                 <div className="row d-flex justify-content-between">
                  <div className="col-7 col-sm-8 col-md-8 col-lg-8">
                  <AuthorProfile author={article.author} post={article}/>
                   </div>
                   <div className="col-5 col-sm-4 col-md-4 col-lg-4 my-auto">
                     <Link to={`/blog/${formatTitle(article.title)}/${article._id}`}> 
                       <button className="read-more-btn medium pe-1">
                         <small className="semibold">Read More <AiOutlineArrowRight /></small> 
                       </button>
                     </Link>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
      ))}
    </>
  );
};


export const RelatedArticleMobile = ( ) => {
  const [post, setPost] = useState({});
  const [author, setAuthor] = useState({});
  const [articles, setArticles] = useState([]);
  const { id } = useParams();
 
  useEffect(() => {
    const fetchPostAndRelatedPosts = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
      setAuthor(data.author);
      setPost(data);
      const { data: relatedPosts } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/related?category=${data.category}&currentPostId=${id}`);
      setArticles(relatedPosts);
    };
    fetchPostAndRelatedPosts();
  }, [id]);

  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  let charLimit;
  let titleCharLimit;

  if (isLargeScreen) {
    charLimit = 95;
    titleCharLimit = 25; 
  } else if (isMediumScreen) {
    charLimit = 80;
    titleCharLimit = 20;  
  } else if (isSmallScreen) {
    charLimit = 85;
    titleCharLimit = 18; 
  }

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); 

  const handleClick = article => {
    const articleTitle = formatTitle(article.title);
    setRedirectTo(`/blog/${articleTitle}/${article._id}`);
  };
  return (
        <div className="row  ">
          <Swiper
      spaceBetween={50}
      slidesPerView={3}
      breakpoints={{
       576: {
          slidesPerView: 1.8,
        },
   648:{   
       slidesPerView: 2.05,
      },
       319: {
          slidesPerView: 1.5,
        },
       480: {
          slidesPerView: 1.8,
        },
      }}
    >
        {articles.map((article, index) => (
      <div className="py-5   ">
   <SwiperSlide className={` slide-container py-2  mx-2   ${index === 0 ? 'ms-3' : ''}`}> 
       <div className="trending-card box-shadow-main-banner trending-img " key={article._id}   onClick={() => handleClick(article)} >
          <img src={`${process.env.REACT_APP_API_URL}/${article.mainBanner}`}
             alt={article.altText}
             className="trending-card-img-display"
             />
            <div className="trending-content p-3 p-sm-3">
            <h5 className="bold">{truncateText(article.title, titleCharLimit)}</h5>
            <h6 className="category-name semibold">{getCategoryName(article.category)}</h6>
            <h6 className="regular">{truncateText(article.mainParagraph, charLimit)}</h6>
            </div>
            </div>
            </SwiperSlide>
            </div>
          ))}
            </Swiper>
        </div>
  );
};
