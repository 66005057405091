import React from "react";
import "../buttonstyle.css";

export const BannerButton = ({ buttonText, icon1, icon2 }) => {
  const [redirectTo, setRedirectTo] = React.useState(null);

  React.useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  const handleClick = () => {
    setRedirectTo("/contact-us");
  };

  return (
    <div>
      <>
        <button
          className=" banner-button   bold d-flex my-auto flex-row   "
          onClick={handleClick}
        >
          <text className="  button-text">{buttonText}</text>
          <text className="my-auto icon icon1">{icon1}</text>
          <text className="my-auto icon icon2">{icon2}</text>
        </button>
      </>
    </div>
  );
};
