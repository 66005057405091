export const content_marketing = [
  {
    main_heading: "  Content Marketing",
    sub_heading1: "Your   ",
    sub_heading_grad: "creative  ",
    sub_heading1_2: "journey starts here  ",
    sub_heading2: "How a Content Marketing Strategy can boost your business?  ",
    sub_heading3:
      " Content Marketing Services We Offer to Businesses in the UAE  ",
    sub_heading4: "Our Process ",
    text1:
      "  Let us be your company's voice in the digital sphere, where we can inform and educate consumers about your website, products, and services. When it comes to the web, content is king. We produce high-quality advertising content for both B2B and B2C companies in Dubai and the UAE. We have a team of experts and professionals to handle all your content creation services. ",
    bullets: [
      {
        list_item: [
          {
            list_item1: "Increase and improve ",
          },
          {
            list_item2: "search engine ",
            linkto: "/search-engine-optimization ",
          },
          { list_item3: "rankings." },
        ],
      },
      {
        list_item:
          "It generates new business leads, nurtures sales prospects, and converts customers.",
      },
      {
        list_item:
          "It builds long-term trust and familiarity with your audience.",
      },
      { list_item: "Enhances the understanding of business proposition." },
      { list_item: "Recruits advocates of your brand." },
    ],
    cover:
      "./Images/services/content-marketing/Trustline-Content-Marketing.svg",
    alt: "Our content marketing team specializes in crafting top-notch content to help your brand thrive.",
  },
];

export const content_marketing_services = [
  {
    title: "Content Strategy ",
    desc: "Content strategy is essential in outlining the user journey, content formats, and content styling. Our content will not only look amazing, but it will also help you achieve your business goals by promoting your brand or campaign across multiple platforms.",
  },
  {
    title: " Buyer Personas",
    desc: "Understanding your audience is crucial to communicating with them. When you work with us, you can use our buyer persona development services to better reach your target audience and create content that meets their needs.",
  },
  {
    title: "Keyword Research ",
    desc: " This will help guide your content development. For businesses in Dubai and the UAE, we will research the keywords that people are searching for and the questions that they ask search engines like Google, which will help us create the content that people are actively looking for so that when they search, your website or content shows up.",
  },
  {
    title: " Competitor Analysis & Social Listening",
    desc: "By analyzing your competitors' online presence and monitoring industry trends and customer feedback, we can identify content gaps and create a strategy that sets you apart from the competition. We use the latest tools and techniques to gather data on your competitors' content, social media activity, and search rankings to ensure your content marketing strategy is effective and relevant to your audience.",
  },
  {
    title: "Creative Blog Writing & Copywriting ",
    desc: "Blogs can boost a company's online presence. The bigger your website's page count, the greater its internet visibility. Blogs boost website traffic, increase conversions, and build customer loyalty. Whether you need content for a website, a blog, or social media, we have the team of expert writers you need.",
  },
  {
    title: "Content Promotion ",
    desc: " We will conduct research on which media channels are best to target your audience and reach your goals, and once we have the content ready, we will then promote it on those specific platforms. We will also continually optimize your content marketing campaign.",
  },
];
export const content_marketing_process = [
  {
    desc: "Looking for Professional Content Marketing Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your Content Marketing needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const content_marketing_process_cards = [
  {
    icon: "./Images/services/content-marketing/Trustline-Content-Research.svg",
    alt: "Boost your content marketing strategy with our content marketing research services.",
    title: "Research",
    bullets: [
      { list_item: " Detailed Client Research " },
      { list_item: " Competitor Research " },
      { list_item: "Target Audience Research  " },
      { list_item: "Keyword Research  " },
    ],
  },

  {
    icon: "./Images/services/content-marketing/Trustline-Content-Creative-Brief.svg",
    alt: "Maximize ROI with our content marketing creative brief. We set objectives, budgets, KPI’s & media channels for your campaign.",
    title: "Creative Brief",
    bullets: [
      { list_item: "Set Objectives  " },
      { list_item: "Campaign Budgeting  " },
      { list_item: " Select Media Channels " },
      { list_item: "Establish KPI’s  " },
    ],
  },
  {
    icon: "./Images/services/content-marketing/Trustline-Content-Development.svg",
    alt: "Drive results with our content development services. Content strategy, production, A/B testing, design, & creative production",
    title: "Development",
    bullets: [
      { list_item: "Develop Content Strategy  " },
      { list_item: "Content Production  " },
      { list_item: "Implement A/B Testing  " },
      { list_item: "Design/creative Production  " },
    ],
  },
  {
    icon: "./Images/services/content-marketing/Trustline-Content-Campaign-Launch.svg",
    alt: "Content marketing campaign launch, review analytics, and generate reports with our expertise.",
    title: "Launch",
    bullets: [
      { list_item: "Set Up Campaign  " },
      { list_item: "Review Analytics  " },
      { list_item: "Optimize Campaign  " },
      { list_item: "Generate Reports  " },
    ],
  },
];
