export const graphic_design = [
  {
    main_heading: "Graphic Design ",
    sub_heading1: "If you can  ",
    sub_heading_grad: "imagine  ",
    sub_heading1_2: "it, we can ",
    sub_heading_grad2: "create ",
    sub_heading1_3: " it ",
    sub_heading2: "How Graphic Designs can boost your business? ",
    sub_heading3:
      "Graphic Designing Services We Offer to Businesses in the UAE ",
    sub_heading4: "Our Process    ",
    text1:
      " Our designers are creative geniuses. They see the world in a unique way, finding patterns and beauty in places that most people don't notice, making connections between thoughts that don't seem to go together, and asking a lot of questions to understand what clients want and why they want it. We love turning ideas into meaningful projects that fit with a good strategy and deliver results. We construct designs that empower and make a strong, captivating visual statement by putting a lot of thought into how the user experience flows and how the design looks. ",
    text2_bold:
      "Transform Your Brand with Eye-Catching Graphic Design and Boost Your Business Beyond Expectations!",
    text3:
      "To effectively communicate with your audience, it's crucial to ensure that your message contains aesthetic value that captures their interest. To establish a connection with your customers, you need to demonstrate creativity and tell a compelling story that resonates with them. Every product or service has a unique story behind it that can help customers feel a sense of connection with your brand. If you're a business in the UAE, looking to expand your online presence, professional graphic design is a powerful tool to make that narrative more engaging and visually appealing. It can help you communicate your message effectively and establish a strong brand identity in the digital landscape.",
    cover: "./Images/services/graphic/Trustline-Graphic-Design.svg",
    alt: "Expert graphic design services from Trustline's talented team of creative designers.",
  },
];

export const graphic_design_services = [
  {
    title: " Packaging & Covers",
    descs: [
      { list_item: " Packaging & Label Design" },
      { list_item: "Book Design " },
      { list_item: "Album Cover Design " },
      { list_item: " Podcast Cover Art" },
      { list_item: " Merch Design" },
    ],
  },
  {
    title: "Graphic Design Art ",
    descs: [
      { list_item: " Illustration Design" },
      { list_item: "Infographic Design " },
      { list_item: "Vector Illustration " },
      { list_item: "Pattern Design " },
      { list_item: " Website Banner Design" },
    ],
  },
  {
    title: "Print Design ",
    descs: [
      { list_item: "Flyer Design " },
      { list_item: " Brochure Design" },
      { list_item: " Poster Design" },
      { list_item: "Signage Design " },
      { list_item: "Catalog Design " },
    ],
  },
  {
    title: "Logo & Branding ",
    descs: [
      { list_item: " Creative Logo Design" },
      { list_item: " Brand Style Guides" },
      { list_item: "Business Cards " },
      { list_item: "Stationery " },
      { list_item: " Fonts & Typography" },
    ],
  },
  {
    title: " Social Media",
    descs: [
      { list_item: " Ad Creative Design" },
      { list_item: "Headers & Covers  " },
      { list_item: "Social Media Post  " },
      { list_item: " Newsletter & eBook " },
      { list_item: "Thumbnails Design " },
    ],
  },
];
export const graphic_design_process = [
  {
    desc: "Looking for Professional Graphic Design Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your Graphic Design needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const graphic_design_process_cards = [
  {
    icon: "./Images/services/graphic/Trustline-Graphic-Design-Define.svg",
    alt: "From market research to design presentation, our graphic design process meets all customer needs.",
    title: "Define",
    bullets: [
      { list_item: " Comprehensive Market Research " },
      { list_item: "Recognize Customer Needs  " },
      { list_item: "Develop Mood boards  " },
      { list_item: "Present Design Brief  " },
    ],
  },

  {
    icon: "./Images/services/graphic/Trustline-Graphic-Design-Visuals.svg",
    alt: "Capture your audience's attention with stunning visuals and designs. Our graphic design team brings your ideas to life.",
    title: "Design",
    bullets: [
      { list_item: "Set Timeframes & Deliverables  " },
      { list_item: "Idea Sketching  " },
      { list_item: "Design creation  " },
      { list_item: "Copywriting & Spellcheck  " },
    ],
  },
  {
    icon: "./Images/services/graphic/Trustline-Design-Feedback.svg",
    alt: "With our iterative feedback and approval system, you can streamline your graphic design process.",
    title: "Feedback",
    bullets: [
      { list_item: " Internal Review " },
      { list_item: "Present Design  " },
      { list_item: "Make Revisions  " },
      { list_item: "Client Approval  " },
    ],
  },
  {
    icon: "./Images/services/graphic/Trustline-Graphic-Design-Delivery.svg",
    alt: "From style guides to final delivery. We provide all file formats and design handover.",
    title: "Delivery",
    bullets: [
      { list_item: "Deliver Style Guides  " },
      { list_item: " Design Handover " },
      { list_item: "Submission of all file formats  " },
      { list_item: "Final Delivery  " },
    ],
  },
];
