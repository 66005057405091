import React from "react";
import DefaultNav from "../../components/navbar/defaultnav/DefaultNav";

import { tandc } from "../../data/termsofuse";
import GTM from "../../googletagmanager/GTM";
import { TandCHelmet } from "../../preclicktags/PreClick";

const TermsandConditions = () => {
  const handleClick = () => {
    window.open("https://goo.gl/maps/S16bpXyuC3JnSk6x5", "_blank");
  };
  return (
    <>
      <GTM />
      <TandCHelmet />
      <DefaultNav />
      <section
        className="terms manrope mt-5 background-baby"
        style={{ color: "#1a1a1a" }}
      >
        <div className="container section-padding ">
          {tandc.map((val) => (
            <>
              <h1 className="bold text-center">{val.Head}</h1>
              <p className="medium justify-content-start pt-3">
                {val.main_desc}
              </p>
              {val.descs.map((item) => (
                <p className="medium justify-content-start py-1">{item.desc}</p>
              ))}
              <ul>
                {val.lists.map((item) => (
                  <li>{item.li}</li>
                ))}
              </ul>
              {val.desc2.map((item) => (
                <p className="medium justify-content-start py-1">{item.desc}</p>
              ))}
              <p className="medium justify-content-start py-1">
                {val.sub7_desc}
              </p>
              <p>
                <ul className="list-unstyled pb-5 w-lg-50 w-100 gap-1 semibold">
                  <li className="py-1">
                    <b>{val.li1}</b>{" "}
                  </li>
                  <li
                    role="button"
                    onClick={handleClick}
                    style={{ color: "#084a44", textDecoration: "none" }}
                    className=" py-2 "
                  >
                    {val.li2}
                  </li>
                  <a
                    href="tel:+9714-397-8705"
                    style={{ color: "#084a44", textDecoration: "none" }}
                  >
                    <li className="   ">{val.li3}</li>
                  </a>
                  <a
                    href="tel:+971-58-192-0960"
                    style={{ color: "#084a44", textDecoration: "none" }}
                  >
                    <li className="py-1 py-md-2">{val.li3_2}</li>
                  </a>
                  <a
                    href="mailto:hello@trustlinegifts.com"
                    style={{ color: "#084a44", textDecoration: "none" }}
                  >
                    <li>{val.li4}</li>
                  </a>
                </ul>
              </p>
            </>
          ))}
        </div>
      </section>
    </>
  );
};

export default TermsandConditions;
