export const thankyou = [
  {
    title1: "Thank  ",
    title2: "you!",
    alt: "Thank you for your request. Our team will be in touch with you shortly.",
    desc: "We have received your request and our team will get in touch with you shortly. In the meantime, feel free to explore our social media pages to learn about upcoming promotions and discounts.",
  },
];
export const images = [
  "./Images/thankyou/Trustline-Thank-You.svg",
  "./Images/thankyou/Trustline-Thank-You-after.svg",
  "./Images/thankyou/Thank_You_3.svg",
];
