import React from "react";
import { HomeScreen } from "./home/HomeScreen";

import Footer from "../components/footer/Footer";
import Thankyou from "./thankyou/Thankyou";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ServicesScreen from "./services/ServicesScreen";
import Seo from "./services/services-sections/Seo";
import Ppc from "./services/services-sections/Ppc";
import Email from "./services/services-sections/Email";
import DisplayVideo from "./services/services-sections/DisplayVideo";
import Web from "./services/services-sections/Web";
import DigitalStrategy from "./services/services-sections/DigitalStrategy";
import SocialMedia from "./services/services-sections/SocialMedia";
import ContentMarketing from "./services/services-sections/ContentMarketing";
import GraphicDesign from "./services/services-sections/GraphicDesign";
import NotFound from "./not-found/NotFound";
import AboutUsScreen from "./about-us/AboutUsScreen";
import { ContactScreen } from "./contact-us/ContactScreen";
import PrivacyPolicy from "./terms-of-use/PrivacyPolicy";
import TermsandConditions from "./terms-of-use/Terms&Conditions";
import CookiePolicy from "./terms-of-use/CookiePolicy";
import CookieConsent from "../cookie/CookieConsent";
import Sitemap from "./sitemap/Sitemap";
import SitemapXML from "../sitemap-xml/SitemapXML.jsx";
import ArticlePage from "./blogs/article/ArticlePage";
import BlogHome from "./blogs/blog-page/BlogHome";
 

export const Pages = () => {
  return (
    <>
      <CookieConsent />
<div className="main-content">
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/contact-us" element={<ContactScreen />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/about" element={<AboutUsScreen />} />
          <Route path="/services" element={<ServicesScreen />} />
          <Route path="/search-engine-optimization" element={<Seo />} />
          <Route path="/pay-per-click-advertising" element={<Ppc />} />
          <Route path="/email-marketing" element={<Email />} />
          <Route path="/web-design-development" element={<Web />} />
          <Route path="/digital-strategy" element={<DigitalStrategy />} />
          <Route path="/social-media-marketing" element={<SocialMedia />} />
          <Route path="/content-marketing" element={<ContentMarketing />} />
          <Route path="/graphic-designing" element={<GraphicDesign />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/sitemap.xml" element={<SitemapXML />} />
          <Route
            path="/display-and-video-advertising"
            element={<DisplayVideo />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsandConditions />}
          />
  {/**blogs route */}
        <Route path="/blog/:title/:id" element={<ArticlePage />} />
        <Route path="/blogs" element={<BlogHome />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      </div>
      <Footer />
    </>
  );
};
