import React, { useEffect } from "react";
import background from '../../Images/backgrounds/Trustline-Digital-Marketing-Dubai.svg';
import logo from '../../Images/logo/white/Trustline_Logo_White.svg';
import "./footer.css";
import {
  float_button,
  footer,
  Getintouch,
  Overview,
  Services,
} from "../../data/Footerdata";

import {
  Facebook,
  Instagram,
  LinkedIn,
  Whatsapp,
  TikTok
} from "../social-icons/SocialLinks";
/*Add bootstrap css*/
const Footer = () => {
  useEffect(() => {
    const footer_logo = document.getElementById("footer_logo");
    footer_logo.addEventListener("click", (e) => {
      e.preventDefault();
      window.location.href = "/";
      setTimeout(() => {
        const div4 = document.getElementById("div4");
        div4.scrollIntoView({ behavior: "smooth" });
      }, 0);
    });
  }, []);
  const handleClickLocation = () => {
    window.open("https://goo.gl/maps/VW7ay4cCb4TSDWSN6", "_blank");
  };

  const handleClickWhatsapp = () => {
    window.open("https://wa.me/message/FNSFJ5QTOGQPE1", "_blank");
  };
  function handleCall() {
    window.location.href = "tel: +971-58-192-0960";
  }
  const [redirectTo, setRedirectTo] = React.useState(null);
  React.useEffect(() => {
    if (redirectTo) {
      if (redirectTo.startsWith("#")) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        window.location.href = redirectTo;
      }
    }
  }, [redirectTo]);
  const handleClickConsultaion = () => {
    const url = "/contact-us";
    if (window.location.pathname === url) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setRedirectTo(url);
    }
  };

  return (
    <>
      <section className="section-padding background-baby">
        {footer.map((item, index) => (
          <div
          key={index}
            className="footer   justify-content-start"
            style={{
              backgroundImage: `url(${background})`,
              alt: `{item.alt_footer}`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <footer className="site-footer">
              <div className="container py-md-5 py-5  ">
                <div className="row pt-lg-5 d-flex  justify-content-start">
                  <div
                    className="footer-logo col-lg-3 col-md-5 col-6 d-flex justify-content-start"
                    role="button"
                    id="footer_logo"
                  >
                    <img
                      className="footer-logo "
                      src={logo}
                      alt={item.alt}
                    ></img>
                  </div>
                </div>
                <div className="row py-lg-4 py-3   footer-content-area">
                  {Getintouch.map((val, index) => (
                    <React.Fragment key={index}>
                      <div className="col-sm-12 col-12 col-lg-5 getin">
                        <div>
                          <h4 className="  py-2"> {item.title1}</h4>
                          <p className="text-justify footer-desc py-2">
                            {item.desc}
                          </p>
                        </div>
                        <div className="footergetintouch">
                          <h2>{val.title2}</h2>

                          <div className="phone2 d-flex flex-column my-auto col-12">
                            <div className=" d-flex  flex-row my-auto">
                              <p className=" my-auto">{val.phone_icon}</p>
                            
                              <a
                                href="tel:+9714-397-8705"
                                className="text-decoration-none  my-auto "
                              >
                                <p className="getintouch-text my-auto">
                                  {val.phone_number1}
                                </p>
                              </a>
                            </div>
                            <div className=" d-flex pt-3 flex-row my-auto">
                              <p className="   my-auto">{val.phone_icon2}</p>

                              <a
                                href="tel: +971-58-192-0960"
                                className="text-decoration-none  "
                              >
                                <p className="getintouch-text  my-auto">
                                  {val.phone_number2}
                                </p>
                              </a>
                            </div>
                          </div>

                          <a
                            href="mailto:hello@trustlinedigital.com"
                            className="text-decoration-none"
                          >
                            <div className="mail2 d-flex py-3">
                              <p className=" my-auto">{val.mail_icon}</p>
                              <p className="getintouch-text  my-auto">{val.mail_id}</p>
                            </div>
                          </a>
                          <a
                            className="visitus2 pb-3 d-flex"
                            role="button"
                            onClick={handleClickLocation}
                          >
                            <p className=" ">{val.location_icon}</p>
                            <p className="getintouch-text   ">{val.location_address}</p>
                          </a>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  {Overview.map((val, index) => (
                    <div className="col-xs-6   col col-lg-2 col-sm-5"  key={index}>
                      <h2> {val.title}</h2>
                      <ul className="footer-links">
                        {val.links.map((link, index) => (
                          <li  key={index}>
                            <a
                              className="  text-decoration-none"
                              href={link.linkto}
                            >
                              <p className="regular">{link.text}</p>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}

                  {Services.map((val, index) => (
                    <div className="col-xs-6 col-lg-3 col-sm-5"  key={index}>
                      <h2> {val.title}</h2>
                      <ul className="footer-links">
                        {val.links.map((link, index) => (
                          <li  key={index}>
                            <a
                              className="  text-decoration-none"
                              href={link.linkto}
                            >
                              <p className="regular">{link.text}</p>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}

                  <div className="col-xs-12 col-lg-1 footer-social-icons hidemeformobile col-sm-1  ">
                    <div>
                      <Facebook />
                      <Instagram />
                      <LinkedIn />
                      <Whatsapp />
                      <TikTok/>
                    </div>
                  </div>
                </div>
                <div className="row hidemeforpc">
                  <div className="d-flex footer-social-icons-mobile flex-row justify-content-center py-3 ">
                    <div className="p-2">
                      <i>
                        <Facebook />
                      </i>
                    </div>
                    <div className="p-2">
                      <i>
                        <Instagram />
                      </i>
                    </div>
                    <div className="p-2">
                      <i>
                        <LinkedIn />
                      </i>
                    </div>
                    <div className="p-2">
                      <i>
                        <Whatsapp />
                      </i>
                    </div>
                    <div className="p-2">
                      <i>
                        <TikTok />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="row  bottom-0">
                  <div className="col-md-12">
                    <p className="  d-flex   justify-content-center align-self-baseline mb-5 mb-sm-0 mx-auto   pt-md-5 mt-md-5 regular">
                      {item.copyright}
                    </p>
                  </div>
                </div>
              </div>
            </footer>

            <div className="showmeformobile" fluid>
              {float_button.map((val, index) => (
                <div className="row"  key={index}>
                  <div className="col-xs-12 ">
                    <div className="floatingbutton ">
                      <div
                        className="col-10 mx-auto semibold  d-flex gap-0  btn-group"
                        role="group"
                      >
                        <button
                          type="button"
                          className="btn   phone-btn "
                          onClick={handleCall}
                        >
                          <i className=" bold">{val.icon}</i>
                          <p className="pt-1 semibold">{val.text1}</p>
                        </button>
                        <button
                          type="button"
                          className="btn   whatsapp-btn semibold"
                          onClick={handleClickWhatsapp}
                        >
                          {val.icon2}
                          <p className="pt-1 semibold">{val.text2}</p>
                        </button>
                        <button
                          type="button"
                          className="btn book-btn semibold "
                          onClick={handleClickConsultaion}
                        >
                          {val.icon3}
                          <p className="pt-1 semibold">{val.text3}</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Footer;
