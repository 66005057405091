import { BsArrowRightCircle } from "react-icons/bs";
import {
  IoChevronForwardCircleOutline,
  IoChevronForwardCircle,
} from "react-icons/io5";
export const primary = [
  {
    buttonText1: "Book Now",
    icon: <BsArrowRightCircle />,
  },
  {
    buttonText1: "Let’s Talk",
    icon: <BsArrowRightCircle />,
  },
  {
    buttonText1: "Learn more",
    icon: <BsArrowRightCircle />,
  },
];

export const secondary = [
  {
    buttonText: "Book a free consultation",
  },
];
export const nav_button = [
  {
    buttonText1: "Get a Free Consultation",
  },

  {
    buttonText1: "Back to Home",
  },
];

export const menu_button = [
  {
    buttonText: "Get a Free Consultation",
  },
];
export const banner_button = [
  {
    buttonText1: "Let’s get started",
    icon1: <IoChevronForwardCircleOutline />,
    icon2: <IoChevronForwardCircle />,
  },
];
