import React from "react";
import DefaultNav from "../../components/navbar/defaultnav/DefaultNav";
import GTM from "../../googletagmanager/GTM";
import { SitemapHelmet } from "../../preclicktags/PreClick";

import "./sitemap.css";

function Sitemap() {
  const pages = [
    { title: "Home", link: "/" },
    { title: "About", link: "/about" },
    { title: "Contact", link: "/contact-us" },
    { title: "Services", link: " /services  " },
    { title: "Blogs", link: " /blogs  " },
    {
      title: "Search Engine Optimization",
      link: "/search-engine-optimization  ",
    },
    {
      title: " Pay-Per-Click Advertising",
      link: " /pay-per-click-advertising  ",
    },
    { title: "Email Marketing", link: "/email-marketing " },
    {
      title: "Web Design and Development",
      link: "  /web-design-development ",
    },
    { title: "Digital Strategy", link: "  /digital-strategy " },
    { title: "Social Media Marketing", link: "  /social-media-marketing  " },
    { title: "Content Marketing", link: "  /content-marketing " },
    { title: "Graphic Design", link: "  /graphic-designing  " },
    {
      title: "Display and Video Advertising",
      link: "/display-and-video-advertising",
    },
    { title: "Privacy Policy", link: "/privacy-policy" },
    { title: "Terms & conditions", link: "/terms-and-conditions" },
    { title: "Cookie Policy", link: "/cookie-policy" },
  ];
  const pageList = pages.map((page, index) => {
    return (
      <li className="sitemap-item mx-auto" key={index}>
        <a className="sitemap-link " href={page.link}>
          {page.title}
        </a>
      </li>
    );
  });

  return (
    <>
      <GTM />
      <SitemapHelmet />
      <DefaultNav />
      <section className="background-baby">
        <div>
          <div className="sitemap-container container section-padding   pb-5 ">
            <h1 className="manrope text-center bold py-5">Sitemap</h1>

            <ul className="sitemap-list semibold   ">{pageList}</ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sitemap;
