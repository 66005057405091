import { HiOutlinePhone } from "react-icons/hi2";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { IoIosArrowDropdown, IoIosArrowDropdownCircle } from "react-icons/io";
 export const home_hero = [
  {
    head1: "We are Trustline",
    head2: "Your Gateway to Quantitative Marketing",
    button_text1: "Want to Make Your Vision a Reality?",
    button_text2: "Click Here",
    icon: <IoIosArrowDropdown />,
    iconhover: <IoIosArrowDropdownCircle />,
  },
];
export const home_title = [
  {
    services: "Our Services",
    how_we_do_it: "How We Do It?",
    why_choose_us: "Why Choose Us?",
  },
];

export const wedo = [
  {
    mainheading: " We do",
    t1: "Search Engine Optimization",
    t2: " Email Marketing",
    t3: "Pay-per-click Advertising",
    t4: "  Digital Strategizing",
    t5: "Graphic Design",
    t6: "Web Design & Development",
    t7: "Content Marketing",
    t8: "Social Media Marketing",
    t9: "Display Advertising",
    t10: "Video Advertising",
    desc: "We are a team of highly analytical, result-oriented individuals. In the digital age, statistics are essential, and Trustline is an expert with them. We place a high value on quantifying marketing insights. You can trust that we will deliver the most effective digital marketing services in Dubai and the UAE.",
    text1: "Talk to our Marketing Specialists Today for",
    text2: "Free!",
    img: "./Images/home/we-do/Trustline-Digital-Marketing-Services.svg",
    alt: "Digital marketing expert services including SEO, PPC, graphic design, social media marketing, UI/UX and website design.",
  },
];
export const wedo_animation = [
  "Search Engine Optimization",
  " Email Marketing",
  "Pay-per-click Advertising",
  "  Digital Strategizing",
  "Graphic Design",
  "Web Design & Development",
  "Content Marketing",
  "Social Media Marketing",
  "Display Advertising",
  "Video Advertising",
];
export const Howwedoit = [
  {
    id: 1,
    title: "Send Brief",
    desc: "Provide us with a detailed brief and a list of what needs to be done before we can get started on your project.",
    alt1: "Our team of experts are ready to start your project. send us your brief today.",
    alt2: "Send us your detailed brief and our team of experts will start with your project.",
    desc2: "  ",
    image1:
      "./Images/home/ How-we-do-it/Company Green Icons/Trustline-Submit-Brief.svg",
    image2:
      "./Images/home/ How-we-do-it/Spring Green Icons/Trustline-Send-Detailed-Brief.svg",
  },
  {
    id: 2,
    title: "Review",
    alt1: "Get your voice heard with our review process. Share your thoughts for better results.",
    alt2: "Review and provide us with your feedback and let's make the necessary adjustments.",
    desc: "Let us know your thoughts on whether we should proceed as is or if any adjustments need to be made.",
    image1:
      "./Images/home/ How-we-do-it/Company Green Icons/Trustline-Review-Process.svg",
    image2:
      "./Images/home/ How-we-do-it/Spring Green Icons/Trustline-Review-Feedback.svg",
  },
  {
    id: 3,
    title: "Approve",
    alt1: "Approve the draft & proceed with the services you've showed interest in.",
    alt2: "Approve draft & move forward with desired services, our team is ready to bring your vision to life.",
    desc: "Approve the draft to proceed with the services you’ve shown an interest in. ",
    image1:
      "./Images/home/ How-we-do-it/Company Green Icons/Trustline-Draft-Approve.svg",
    image2:
      "./Images/home/ How-we-do-it/Spring Green Icons/Trustline-Approve-Services.svg",
  },
  {
    id: 4,
    title: "Launch",
    alt1: "Grow with Trustline's excellent quality services delivered on time. Leave us a review if you love it.",
    alt2: "Experience Growth with Our On-Time, Excellent Quality Services. Share Your Review Today.",
    desc: "We execute the approved strategy and launch the project, keeping you informed of progress every step of the way.",
    image1:
      "./Images/home/ How-we-do-it/Company Green Icons/Trustline-Grow-Review.svg",
    image2:
      "./Images/home/ How-we-do-it/Spring Green Icons/Trustline-Services-Grow.svg",
  },
];
export const whychooseus = [
  {
    id: 1,
    image1:
      "./Images/home/ Why-Choose-Us/Default/Trustline-Enhanced-Expertise.svg",
    image2:
      "./Images/home/ Why-Choose-Us/Hover/Trustline-Enhanced-Expertise-Tools.svg",
    alt1: "Get enhanced expertise with Trustline's access to new tools and skills.",
    alt2: "Get access to our expertise and tools to enhance your business with our digital marketing services.",
    head: "Enhanced Expertise ",
    desc: "We give you access to new expertise & tools.",
  },
  {
    id: 2,
    image1:
      "./Images/home/ Why-Choose-Us/Default/Trustline-Creative-Marketing.svg",
    image2:
      "./Images/home/ Why-Choose-Us/Hover/Trustline-Creative-Marketing-Activity.svg",
    alt1: "Add our creative touch to your marketing efforts.",
    alt2: "Boost creativity in your campaigns with our marketing services.",
    head: "Creative Marketing ",
    desc: "We add creativity to your marketing activity.",
  },
  {
    id: 3,
    image1:
      "./Images/home/ Why-Choose-Us/Default/Trustline-New-Perspective.svg",
    image2:
      "./Images/home/ Why-Choose-Us/Hover/Trustline-Fresh-Perspective.svg",
    alt1: "Transform your marketing with new thinking and fresh perspectives from our digital marketing experts.",
    alt2: "We help you gain a fresh perspective and innovative ideas for your marketing strategy.",
    head: "New Perspective",
    desc: "We stimulate new thinking & perspective.",
  },
  {
    id: 4,
    image1: "./Images/home/ Why-Choose-Us/Default/Trustline-Lower-Costs.svg",
    image2: "./Images/home/ Why-Choose-Us/Hover/Trustline-Reduced-Costs.svg",
    alt1: "Save with our scalable services & reduced costs for your business.",
    alt2: "We provide scalable services with reduced costs.",
    head: " Lower Costs",
    desc: "Working with us reduces your cost & provides scalable services.",
  },
];

export const contactus = [
  { title1: "Talk to Us" },

  {
    id: 1,
    icon: <HiOutlinePhone />,
    text: "+9714 397 8705",
  },
  {
    id: 2,
    icon: <HiOutlineMail />,
    text: "hello@trustlinedigital.ae",
  },
  { title2: "Visit Us" },
  {
    id: 3,
    icon3: <HiOutlineLocationMarker />,
    text3: "Unit 404, Nastaran Tower, Jadaf Waterfront, Al Jadaf, Dubai, UAE",
  },
];
export const qr_code = [
  {
    background_pc: "./Images/qr-code/Trustline-Vision-To-Reality-desktop.svg",
    background_tab: "./Images/qr-code/Trustline-Vision-To-Reality-tablet.svg",
    background_mob: "./Images/qr-code/Trustline-Vision-To-Reality-Mobile.svg",
    alt: "We help you turn your visions into a reality. Connect with us through the QR code to achieve your marketing goals.",
    title: "Want to make your vision a reality?",
    sub: "Scan the QR code & get in touch with us to learn how we can help you achieve your goals.",
  },
];
