export const smm = [
  {
    main_heading: "Social Media Marketing  ",
    sub_heading1: "Looking for ways to   ",
    sub_heading_grad: " boost ",
    sub_heading1_2: "your Social Media presence?  ",
    sub_heading2: " How Display & Video Advertising can boost your business? ",
    sub_heading3:
      " Social Media Marketing Services We Offer to Businesses in the UAE  ",
    sub_heading4: "Our Process ",
    text1:
      "The experts at Trustline can show you how to get the most out of your social media channels and expand your reach like never before. Managing multiple social media accounts can be time-consuming, and many brands either fail to recognize the potential influence of social media on their business or fail to allocate sufficient resources to this area. We are here to help Businesses in the UAE, make a significant impression online by developing a social media strategy that will help their business grow. ",
    bullets: [
      {
        list_item: "Helps build relationships with new and existing customers.",
      },
      { list_item: "Leverages organic and real-time feedback from customers." },
      { list_item: "Offers an instant and positive customer experience." },
      { list_item: "Builds your brand story." },
      { list_item: "Allows you to reach your social media marketing goals." },
    ],
    cover: "./Images/services/smm/Trustline-Social-Media-Marketing.svg",
    alt: "Boost your brand's online presence with our Social Media Marketing services. Expert strategies for maximum ROI.",
  },
];

export const smm_services = [
  {
    title: "Social Media Strategy ",
    desc: "For your company's social media presence, we have a team of professionals ready to craft a plan. We'll take care of everything, from determining which platforms will work best to accomplish your goal to establishing a realistic budget.",
  },
  {
    title: " Community Management",
    desc: "As your social media presence grows, you'll receive questions, comments, and more from your audience. It's important that these are answered right away. This gives people a good impression of your brand and makes them feel more connected to it. We'll take care of it for you professionally.",
  },
  {
    title: "Paid Promotion Campaign ",
    desc: "Through social media advertising your brand has a better chance of reaching and engaging with its audience. It's a great way to get people interested in your products and services and, eventually, to get them to buy them. We'll handle every aspect of your social media campaigns for you.",
  },
  {
    title: " Designing Creative Content",
    desc: "We are creative geniuses. We have skilled Social Media Marketers that will make sure all brand content on social media is consistent with the business goals and tone.We will make sure that our clients are satisfied with the designs we present.",
  },
  {
    title: " Analytics",
    desc: "Analytics on new followers, audience size, replies to posts, shares, likes, and comments, and more, will be sent to you regularly. We will deliver weekly insights and discuss how we can further optimize the campaign.",
  },
];
export const smm_process = [
  {
    desc: "Looking for Professional Social Media Marketing Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your Social Media Marketing needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const smm_process_cards = [
  {
    icon: "./Images/services/smm/Trustline-Social-Media-Research.svg",
    alt: "Detailed client research, competitor research, keyword research, select media platforms.",
    title: "Research",
    bullets: [
      { list_item: "Detailed Client Research" },
      { list_item: "Competitor Research" },
      { list_item: " Keyword Research" },
      { list_item: "Select Media Platforms" },
    ],
  },
  {
    icon: "./Images/services/smm/Trustline-Social-Media-Objective.svg",
    alt: "Set social media marketing objectives, develop strategies, establish KPIs, and budget campaigns.",
    title: "Objective",
    bullets: [
      { list_item: "Set Campaign Objectives  " },
      { list_item: "Develop Strategy " },
      { list_item: "Establish KPI’s " },
      { list_item: "Campaign Budgeting  " },
    ],
  },
  {
    icon: "./Images/services/smm/Trustline-Social-Media-Development.svg",
    alt: "Social media campaign set-up with content copy, post design, and hashtag list for optimal reach.",
    title: "Development",
    bullets: [
      { list_item: "Content Copy  " },
      { list_item: "Post Design  " },
      { list_item: "Hashtag List  " },
      { list_item: "Set Up Campaign  " },
    ],
  },

  {
    icon: "./Images/services/smm/Trustline-Social-Media-Launch.svg",
    alt: "Optimize social media launch with A/B testing, analytics review, and paid strategies.",
    title: "Launch",
    bullets: [
      { list_item: "A/B Testing  " },
      { list_item: "Review Analytics  " },
      { list_item: "Optimize Campaign  " },
      { list_item: "Implement Paid Social Media Strategies  " },
    ],
  },
];
