import React from "react";
import "./transparentnav.css";
import { nav } from "../../../data/nav";
import { nav_button } from "../../../data/buttons";
import { NavButtonWhite } from "../../buttons/nav-button/NavButton";
import ToggleButton from "../defaultnav/ToggleButton";
import logo from '../../../Images/logo/white/Trustline-White-Logo.svg';
 
const TransparentNav = () => {
  return (
    <>
      <div className="transparent  ">
        <nav className="navbar navbar-expand-lg  transparent-nav   fixed-top">
          <div className="container">
            {nav.map((val, index) => (

              <a className="navbar-brand" href="/" key={index}>
                <img src={logo} alt={val.alt2} />
              </a>
            ))}
            <div className="d-flex flex-row navbar-button-hide">
              <NavButtonWhite
                buttonText={nav_button[0].buttonText1}
                redirect="/contact-us"
              />
              <div className="ps-lg-5 ps-md-3 ps-0 my-auto">
                <ToggleButton />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default TransparentNav;
