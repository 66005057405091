import React from "react";
import { useState, useEffect } from "react";
import { BgAnimation } from "./BgAnimation";
import "../homescreen.css";
import { home_hero } from "../../../data/Homedata";

const HeroHome = ({ targetDivRef }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    const targetDiv = targetDivRef.current;
    const targetTop = targetDiv.offsetTop;
    const targetHeight = targetDiv.offsetHeight;
    const windowHeight = window.innerHeight;
    const offset = Math.floor((targetHeight - windowHeight) * 0.001) - 30;
    window.scrollTo({
      top: targetTop + offset,
      behavior: "smooth",
    });
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 5500);
  }, []);

  return (
    <>
      {/* Hero Section*/}
      <section className="hero">
        {/*Navbar*/}
        {/*Hero Background*/}
        <BgAnimation />
        {home_hero.map((val, index) => (
          <div className=" " key={index}>
            <div className="animated-title">
              <div className="text-top">
                <div>
                  <span>
                    <h3 className="bold">{val.head1}</h3>
                  </span>
                  <span></span>
                </div>
              </div>
              <div className="text-bottom">
                <div>
                  <h2 className="semibold">{val.head2}</h2>{" "}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="row">
          {showButton && (
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
            >
              <button className="hero-button ">
                {isHovered ? (
                  <div className="div-width ">
                    <h5>{home_hero[0].button_text2}</h5>
                    <h5>{home_hero[0].iconhover}</h5>
                  </div>
                ) : (
                  <div className="div-width ">
                    <h5>{home_hero[0].button_text1}</h5>
                    <h5>{home_hero[0].icon}</h5>
                  </div>
                )}
              </button>
            </div>
          )}
        </div>
        {/*Button*/}
      </section>
      {/* Target component */}
    </>
  );
};

export default HeroHome;
