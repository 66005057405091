import React, { useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "bootstrap/dist/css/bootstrap.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { home_title } from "../../../data/Homedata";
import { IoOpenOutline } from "react-icons/io5";
import { services_cards } from "../../../data/servicescards";
import { IoIosArrowDropright } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { SecondaryButton } from "../../../components/buttons/secondary-button/SecondaryButton";
import { secondary } from "../../../data/buttons";
const HomeServices = () => {
  const [hoveredIndexes, setHoveredIndexes] = useState(
    Array(services_cards.length).fill(-1)
  );
  const isMobileorTablet = useMediaQuery({
    query: "(max-width: 992px)",
  });
  const isDesktop = useMediaQuery({
    query: "(min-width: 993px)",
  });
  const handleMouseEnter = (groupIndex, cardIndex) => {
    setHoveredIndexes((prev) => {
      const indexes = [...prev];
      indexes[groupIndex] = cardIndex;
      return indexes;
    });
  };

  const handleMouseLeave = (groupIndex) => {
    setHoveredIndexes((prev) => {
      const indexes = [...prev];
      indexes[groupIndex] = -1;
      return indexes;
    });
  };
  return (
    <>
      <section className="services section-padding background-baby">
        <div className="container   ">
          <div className="row p-4">
            {home_title.map((val, index) => (
              <div className="col-sm-12 bold text-center" key={index}>
                <h2 className="bold py-lg-3 py-2"> {val.services}</h2>
              </div>
            ))}
          </div>

          {services_cards.map((val, groupIndex, index2) => (
            <React.Fragment key={index2}>
              <div className="row py-3" >
                <div className="d-flex col-12    bold justify-content-start ">
                  <h3 className=" pe-2  jetspan bold">
                    {val.span1}
                    <text className=" " style={{ color: "#666666" }}>
                      {val.span2}
                    </text>
                  </h3>
                </div>
                <div>
                  <div className="row d-flex    ">
                    <Swiper
                      spaceBetween={15}
                      modules={[Pagination]}
                      className="mySwiper p-2"
                      breakpoints={{
                        0: {
                          slidesPerView: 1.5,
                        },
                        480: {
                          slidesPerView: 1.8,
                        },
                        768: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                      }}
                    >
                      <div className="row">
                        {val.cards.map((card, cardIndex ) => (
                          <React.Fragment key={`${groupIndex}-${cardIndex}`}  >
                            <SwiperSlide className="swiper2 p-sm-3  p-3">
                              <div className="row">
                                <div
                                  className={`home-services-section-card ${
                                    hoveredIndexes[groupIndex] === cardIndex &&
                                    isDesktop
                                      ? "home-services-section-card-back"
                                      : "home-services-section-card"
                                  }`}
                                  key={cardIndex}
                                  onMouseEnter={() =>
                                    handleMouseEnter(groupIndex, cardIndex)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(groupIndex)
                                  }
                                >
                                  {hoveredIndexes[groupIndex] === cardIndex &&
                                  isDesktop ? (
                                    <div
                                      className="card-back-side  "
                                      style={{ color: "white" }}
                                    >
                                      <div className="col-12 col-sm-12  mx-auto p-4  pe-5 justify-content-start bottom-0    position-absolute d-flex my-auto flex-column">
                                        <h3
                                          className="semibold color-white py-lg-3 py-2 "
                                          style={{ color: "white" }}
                                        >
                                          {card.title}
                                        </h3>
                                        <h5
                                          className="medium color-white showmeforpc"
                                          style={{ color: "white" }}
                                        >
                                          {card.desc}
                                        </h5>
                                        <div className="col-12  col-sm-12 ">
                                          <div className=" d-flex  py-2 py-sm-3 ">
                                            <Link
                                              className="home-services-findoutmore text-decoration-none"
                                              to={card.link}
                                              target="_top"
                                            >
                                              <text className="bold">
                                                Find out more
                                                <i className="ps-1">
                                                  <IoIosArrowDropright />
                                                </i>
                                              </text>
                                            </Link>
                                          </div>
                                        </div>
                                        <SecondaryButton
                                          buttonText={secondary[0].buttonText}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="card-front-side">
                                      <div className="row d-flex flex-row py-md-2 py-1 align-items-baseline">
                                        <div className="col-12 col-sm-12 justify-content-start d-flex my-auto flex-column">
                                          <h3 className="semibold py-xl-3 py-sm-2 py-1">
                                            {card.title}
                                          </h3>
                                        </div>
                                        <div className="col-12 col-sm-12   d-flex pb-5 services-cards-home-image position-absolute justify-content-end me-0">
                                          <img src={card.img} alt={card.alt} />
                                        </div>
                                        <div className=" d-flex justify-content-end findout-link position-absolute p-2 p-sm-3 ">
                                          {isMobileorTablet ? (
                                            <Link
                                              className="services-link text-decoration-none"
                                              to={card.link}
                                              target="_top"
                                            >
                                              <text className="bold">
                                                Find out more
                                                <i className="ps-1">
                                                  <IoOpenOutline />
                                                </i>
                                              </text>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SwiperSlide>
                          </React.Fragment  >
                        ))}
                      </div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </section>
    </>
  );
};

export default HomeServices;
