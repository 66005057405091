export const web = [
  {
    main_heading: "Web Design & Development  ",
    sub_heading1: "Making websites that work   ",
    sub_heading_grad: "for you  ",
    sub_heading1_2: "and your customers  ",
    sub_heading2: "How a Website can boost your business?  ",
    sub_heading3:
      " Web Design & Development Services We Offer to Businesses in the UAE  ",
    sub_heading4: "Our Process ",
    text1:
      "In an internet-driven era where websites are the first face of a company, we help companies in Dubai and throughout the UAE by starting from the website design phase and continuing through development and post-development errands. We will ensure that your website stands out among other companies. We have the most talented web developers and designers who will construct a website that not only performs well but is secure and can be analyzed with a variety of embedded web analytics. ",
    bullets: [
      { list_item: "It establishes the first impression." },
      {
        list_item: [
          {
            list_item1: "It helps your ",
          },
          { list_item4: "digital strategy.", linkto: "/digital-strategy " },
        ],
      },
      { list_item: "It sets the impression for customer service." },
      { list_item: "It builds trust with your audience." },
      { list_item: "Your competitors are doing it. " },
      { list_item: "It creates consistency. " },
    ],
    cover: "./Images/services/web/Trustline-Web-Design-Development.svg",
    alt: "Get a website that speaks to your customers. Our design and development experts create responsive, user-friendly sites.",
  },
];

export const web_services = [
  {
    title: " UI/UX Design",
    desc: "By understanding your requirements, our creative UI/UX designers will design a website that perfectly aligns with your vision. We will create wireframes and present prototypes of your web page to give you a clear picture of the website layout.",
  },
  {
    title: "Coding ",
    desc: "Our team of skilled developers utilize the latest coding techniques and programming languages to create websites that are not only visually appealing but also highly functional and user-friendly. We specialize in developing custom websites tailored to meet the unique needs of our clients.",
  },
  {
    title: "QA & Testing ",
    desc: " Our team will perform quality assurance and testing, which are important components of any website development service. We will ensure that the website works as intended, meets user expectations, and runs smoothly across various devices and browsers.",
  },
  {
    title: "User Acceptance Testing ",
    desc: "To ensure everything is working as it should, our web developer will conduct a user acceptance test. Before your site goes live, we will do detailed performance testing to ensure a smooth transition. If problems are found, our team of professional and experienced web developers will work to resolve them.",
  },
  {
    title: " Analytics",
    desc: "Our developers will evaluate your website's KPIs. Traffic, visibility, accessibility, and SERP ranking Website traffic measures success. Heat maps will track site visitors for our web developers. After evaluating metrics, we'll optimize your website.",
  },
];
export const web_process = [
  {
    desc: "Looking for Professional  Web Design & Development Services in the UAE? Here’s how we do it for our clients in Dubai and throughout the UAE. We have an in-house team of talented and experienced individuals who will handle all your Web Designing and development needs. While this is just a framework of how we approach it, we are always flexible in order to meet your needs.",
  },
];
export const web_process_cards = [
  {
    icon: "./Images/services/web/Trustline-Website-Research.svg",
    alt: "Revamp your website with our design and development process. From market research to client approval, we've got you covered.",
    title: "Research",
    bullets: [
      { list_item: "Comprehensive Market Research  " },
      { list_item: "Design Documentation  " },
      { list_item: " Wireframing " },
      { list_item: "Client Approval  " },
    ],
  },

  {
    icon: "./Images/services/web/Trustline-Website-Development.svg",
    alt: "Our team of expert web designers and developers craft websites that are responsive, secure, and optimized for search engines.",
    title: "Development",
    bullets: [
      { list_item: "Coding  " },
      { list_item: "Responsive Design  " },
      { list_item: " Implement SEO techniques " },
      { list_item: "Ensure Web Security  " },
    ],
  },
  {
    icon: "./Images/services/web/Trustline-Website-Testing.svg",
    alt: "From review to user acceptance testing, our experts handle every aspect of website design and development.",
    title: "Testing",
    bullets: [
      { list_item: "Review  " },
      { list_item: "Prepare Test Cases  " },
      { list_item: "QA & Testing  " },
      { list_item: "User Acceptance Testing  " },
    ],
  },
  {
    icon: "./Images/services/web/Trustline-Website-Launch.svg",
    alt: "Get your website up and running with our maintenance services. Achieve success with our post-deployment assistance.",
    title: "Launch",
    bullets: [
      { list_item: "Go Live  " },
      { list_item: " Monitor Analytics " },
      { list_item: " Site Maintenance " },
      { list_item: "Post-Deployment Assistance  " },
    ],
  },
];
