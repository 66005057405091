import React from "react";
import { reachus } from "../../../data/reach";
import "./location.css";
const Location = () => {
  const handleClick = () => {
    window.open("https://goo.gl/maps/VW7ay4cCb4TSDWSN6", "_blank");
  };

  return (
    <div>
      {reachus.map((val, index) => (
       <React.Fragment key={index}>
          <div
            role="button"
            onClick={handleClick}
            className="location d-flex    flex-row"
          >
            <div role="button" className="location d-flex gap-3 flex-row">
              <h3 className=" align-self-start d-flex">{val.icon_location}</h3>
              <h5 className="bold   ">{val.address}</h5>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
export default Location;
