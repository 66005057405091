import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./services-slider.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { slides } from "../../../data/services/services-slider";
import { useLocation } from "react-router-dom";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  FreeMode,
} from "swiper/core";

SwiperCore.use([Navigation, FreeMode, Pagination, Autoplay]);
 
 
export default function Swiperslider() {
  const [swiper, setSwiper] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const location = useLocation();
  const onSlideNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const onSlidePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick(url) {
    setRedirectTo(url);
  }

  const isMobile = window.innerWidth < 768;

  const slidesPerView = isMobile ? 3 : window.innerWidth < 1024 ? 4 : 6;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    const slideIndex = slides.findIndex(
      (slide) => slide.url === window.location.pathname
    );
    setActiveSlideIndex(slideIndex === -1 ? 0 : slideIndex);
  }, []);

  function handleSlideChange(swiper) {
    setActiveSlideIndex(swiper.activeIndex);
    const slide = slides[swiper.activeIndex];
    window.history.pushState(null, "", slide.url);
  }

  return (
    <>
      <section className="service-slider section-padding pb-5 text-center  ">
        <div className="container">
          <h3 className="pb-lg-5 pb-3 semibold">We Also Provide</h3>
          <div className="row">
            <div className="col-1">
         <div className="swiper-buttons">
                <button
                  className="swiper-button-prev2     "
                  onClick={onSlidePrev}
                >
                  <i>
                    <IoChevronBackOutline />
                  </i>
  </button> 
  </div>
            </div>
            <div className="col-10  ">
              <Swiper
                navigation={{
                  prevEl: ".swiper-button-prev2",
                  nextEl: ".swiper-button-next2",
                }}
                slidesPerView={slidesPerView}
                spaceBetween={0}
                initialSlide={activeSlideIndex}
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                activeIndex={activeSlideIndex}
                freeMode={true}
                pagination={{
                  clickable: true,
                  bulletClass: "swiper-pagination-bullet",
                  bulletActiveClass: "swiper-pagination-bullet-active",
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper-slide text-center ms-md-2  "
              >
                {slides.map((slide, index) => (
                  <SwiperSlide
                    className=" slide-item mb-3 pb-4 pb-lg-5"
                    key={index}
                  >
                    <div
                      className={
                        location.pathname === slide.url
                          ? "slide-background p-1"
                          : "px-2"
                      }
                      onClick={() => handleClick(slide.url)}
                    >
                      <i className="icon-select">{slide.icon}</i>
                      <p>{slide.text}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-1">
          
              <div className="swiper-buttons">
                <button className="swiper-button-next2 " onClick={onSlideNext}>
                  <i>
                    <IoChevronForwardOutline />
                  </i>
                </button>
              </div>
                  
            </div>
          </div>
        </div>
      </section>
    </>
  );
}  

 
