import React from 'react';
 import '../articlepage.css'
import ReactPlayer from 'react-player';
 
const Youtube = ({ videoLink }) => {
  console.log(videoLink);
  
  if (!videoLink) {
    return null;
  }

  return (
    <div className='align-height box-shadow-main-banner'
     style={{position: 'relative', overflow: 'hidden', borderRadius: '16px'}}>
      <ReactPlayer url={videoLink} controls={true} playing={true}
        style={{position: 'absolute', top: 0, left: 0}} width='100%' height='100%' />
    </div>
  );
}



export default Youtube


 
